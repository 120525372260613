<template>
  <div class="app-container">
    <el-form
      ref="params"
      :model="params"
      :inline="true"
    >
      <el-form-item>
        <el-input
          v-model="params.etpName"
          class="input"
          size="mini"
          style="width:150px"
          placeholder="投标企业"
          clearable
          @clear="getList"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="params.projectName"
          class="input"
          size="mini"
          style="width:150px"
          placeholder="项目名称"
          clearable
          @clear="getList"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="params.guaranteeContractNo"
          class="input"
          size="mini"
          style="width:150px"
          placeholder="委托保证合同号"
          clearable
          @clear="getList"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="params.guaranteeId"
          class="input"
          size="mini"
          style="width:150px"
          placeholder="保函唯一标识"
          clearable
          @clear="getList"
        />
      </el-form-item>
        <el-form-item>
        <el-date-picker
          v-model="dateStart"
          value-format="yyyy-MM-dd HH:mm:ss"
          size="mini"
          type="datetime"
          placeholder="申请时间开始"
        />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="dateEnd"
          value-format="yyyy-MM-dd HH:mm:ss"
          size="mini"
          type="datetime"
          placeholder="申请时间结束"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-search"
          @click="getList"
        >查询</el-button>
        <el-button size="mini" type="primary" icon="el-icon-refresh-left" @click="deleteAddForm">重置</el-button>
         <el-button size="mini" type="primary" icon="el-icon-download" @click="getExport">导出</el-button>
      </el-form-item>
    </el-form>
      <br>
    <el-table
      ref="multipleTable"
      size="mini"
      fit
      :data="tableData"
      border
      :height="tableDataHeight"
      v-loading="tableLoading"
      :row-class-name="tableRowClassName"
      :row-key="getRowKey"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" :reserve-selection="true" width="55" align="center" fixed="" />
      <el-table-column type="index" label="序号" width="50" :index="indexMethod" align="center" fixed="" />
       <el-table-column prop="totalMoney" label="收费合计不含税(不含保函类手续费)" align="center" width="130" />
      <el-table-column prop="month" label="收费月度" align="center" width="130" />
      <el-table-column prop="projectCode" label="项目编号" align="center" width="130" />
      <el-table-column prop="projectName" label="项目名称" align="center" width="160" />
      <el-table-column prop="createdTime" label="支付时间" align="center" width="140" />
      <!-- <el-table-column prop="state" label="是否驳回" align="center" width="130">
        <template slot-scope="scope">
          <div>
            {{ scope.row.state===1?'是':'否' }}
          </div>
        </template>
      </el-table-column> -->
       <!-- type:1退款 (判断未成功，1：成功),0支付 -->
       <el-table-column prop="status" label="开具保函状态" align="center">
        <template slot-scope="scope">
          {{ scope.row.status==='02' ? '未上传': '已上传'}}
        </template>
      </el-table-column>
      <el-table-column prop="declarationDate" label="申报日期" align="center" width="130" />
      <el-table-column prop="chargingDate" label="收费日期" align="center" width="130" />
      <el-table-column prop="invoiceType" label="发票类型" align="center" width="130" />
      <el-table-column prop="chargeType" label="收费类型" align="center" width="130" />
      <el-table-column prop="guarantoes" label="被保证人" align="center" width="130" />
      <el-table-column prop="identification" label="纳税识别号" align="center" width="130" />
      <el-table-column prop="organizationCode" label="组织机构代码" align="center" width="130" />
      <el-table-column prop="registeredDistrict" label="省份/直辖市" align="center" width="130" />
      <el-table-column prop="registeredCity" label="地级市/直辖市下属区" align="center" width="130" />
      <el-table-column prop="nationalCategory" label="国标大类" align="center" width="130" />
      <el-table-column prop="nationalIndustry" label="国标行业" align="center" width="130" />
      <el-table-column prop="park" label="园区" align="center" width="130" />
      <el-table-column prop="highTech" label="是否高新" align="center" width="130" />
      <el-table-column prop="supportProgram" label="扶持计划" align="center" width="130" />
      <el-table-column prop="guaranteeAmount" label="担保金额(万元)" align="center" width="130" />
      <el-table-column prop="product" label="产品" align="center" width="130" />
      <el-table-column prop="guaranteeTypes" label="担保种类" align="center" width="130" />
      <el-table-column prop="startDate" label="起始日" align="center" width="130" />
      <el-table-column prop="endDate" label="到期日" align="center" width="130" />
      <el-table-column prop="termMonth" label="贷款期限(月)" align="center" width="130" />
      <el-table-column prop="bank" label="银行" align="center" width="130" />
      <el-table-column prop="branch" label="支行" align="center" width="130" />
      <el-table-column prop="cooperativeInstitutions" label="合作金融机构" align="center" width="130" />
      <el-table-column prop="bankRate" label="银行利率" align="center" width="130" />
      <el-table-column prop="mainContractNo" label="主合同号" align="center" width="130" />
      <el-table-column prop="guaranteeContractNo" label="委托保证合同号" align="center" width="130" />
      <el-table-column prop="riskRatio" label="风险比例" align="center" width="130" />
      <el-table-column prop="guaranteeRate" label="担保费率" align="center" width="130" />
      <el-table-column prop="guaranteeFee" label="担保费" align="center" width="130" />
      <el-table-column prop="guaranteeExcluding" label="担保费(不含税)" align="center" width="130" />
      <el-table-column prop="reviewRate" label="评审费率" align="center" width="130" />
      <el-table-column prop="reviewFee" label="评审费" align="center" width="130" />
      <el-table-column prop="assessmentFee" label="评审费(不含税)" align="center" width="130" />
      <el-table-column prop="serviceRate" label="手续费率" align="center" width="130" />
      <el-table-column prop="serviceCharge" label="手续费" align="center" width="130" />
      <el-table-column prop="handlingCharge" label="手续费(不含税)" align="center" width="130" />
      <el-table-column prop="entrustedLoan" label="委贷利息率" align="center" width="130" />
      <el-table-column prop="interest" label="利息(价税合计)" align="center" width="130" />
      <el-table-column prop="interestExcluding" label="利息(不含税)" align="center" width="130" />
      <el-table-column prop="totalCharges" label="收费合计" align="center" width="130" />
      <el-table-column prop="totalMoney" label="收费合计(不含税)" align="center" width="130" />
      <el-table-column prop="firstGuarantee" label="是否首次担保" align="center" width="130" />
      <el-table-column prop="a" label="A角" align="center" width="130" />
      <el-table-column prop="b" label="B角" align="center" width="130" />
      <el-table-column prop="businessDivision" label="事业部" align="center" width="130" />
      <el-table-column prop="office" label="所属办事处" align="center" width="130" />
      <el-table-column prop="firstFinancingBank" label="是否银行首次融资" align="center" width="130" />
      <el-table-column prop="customerTabulation" label="客户表划型" align="center" width="130" />
      <el-table-column prop="contractNo" label="保证合同号" align="center" width="130" />
      <el-table-column prop="projectSource" label="项目来源" align="center" width="130" />
      <el-table-column prop="classificationInformation" label="免税划型信息" align="center" width="130" />
      <el-table-column prop="chargeId" label="收费id" align="center" width="130" />
      <el-table-column prop="addressTelephone" label="地址、电话" align="center" width="130" />
      <el-table-column prop="bankAccount" label="开户行、账号" align="center" width="130" />
      <el-table-column prop="remarks" label="发票备注" align="center" width="130" />
      <el-table-column prop="mailbox" label="企业接收电票邮箱" align="center" width="130" />
      <el-table-column prop="additionalNotes" label="补说明备注" align="center" width="130" />
      <el-table-column label="企业划型" align="center">
        <el-table-column prop="employeesNo" label="上年度员工人数" width="120" align="center" />
        <el-table-column prop="assets" label="上年度资产规模(万)" width="130" align="center" />
        <el-table-column prop="operating" label="上年度营业收入(万)" width="130" align="center" />
        <el-table-column prop="industryCode" label="行业代码" width="120" align="center" />
        <el-table-column prop="industryName" label="行业名称" width="120" align="center" />
      </el-table-column>
    </el-table>
    <!-- 分页 -->
      <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="params.currentPage"
          :limit.sync="params.pageSize"
          @pagination="getList"
        />
  </div>
</template>

<script>
import { listZdb,downloadZdb} from '@/api/guarantee/finance'
export default {
  name: 'ZdbList',
  data() {
    return {
      jbVisible:false,
      fileName: '压缩包',
      jbOptions:[],
      payTypeOptions: [],
      multipleSelection: [],
      ids: [],
      tableLoading:false,
      id: '',
      name: '',
      ziptype: '',
      tableDataHeight:0,
      dateStart:undefined,
      dateEnd:undefined,
      params: {
        'projectName':'',
        'projectCode':'',
        'guaranteeId':'',
        'guaranteeContractNo':'',
        'etpName': '',
        'currentPage': 1,
        'pageSize': 10,
        'status': '',
        'start':undefined,
        'end':undefined
      },
      total: 0,
      windowHeight:0,
      // 假数据
      tableData: []
    }
  },
  created() {
    this.getList()
    this.updateWindowHeight()
  },
  methods: {
    updateWindowHeight() {
      this.tableDataHeight = window.innerHeight-300
      console.log(this.tableDataHeight)
    },
    indexMethod(index){
        return (this.params.currentPage-1)*this.params.pageSize+index+1;
      },

    // 获取列表页请求
    getList() {
      if(this.tableLoading)return;
      if(this.dateStart){
        this.params.start = new Date(this.dateStart).getTime()
      }
      if(this.dateEnd){
        this.params.end = new Date(this.dateEnd).getTime()
      }
      this.tableLoading=true;
      listZdb(this.params).then(res => {
         this.tableLoading=false
         if(res.data.success){
            this.tableData = res.data.result.records
            this.total = res.data.result.total
         }else{
            this.$msgErr(res.data.message)
         }
         
      })
    },
    getExport(){
        if (this.ids.length === 0) {
          this.$message({
            message: '请选择数据导出',
            type: 'warning'
          })
        } else {
          const data={
            id:this.ids
          }
          downloadZdb(data).then(res => {
            const binaryData = []
            binaryData.push(res.data)
            let url = window.URL.createObjectURL(new Blob(binaryData, {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }))
                const aLink = document.createElement('a')
                aLink.style.display = 'none'
                aLink.href = url
                aLink.setAttribute('download', '中担保流水导出.xls')
                document.body.appendChild(aLink)
                aLink.click()
                document.body.removeChild(aLink)
                window.URL.revokeObjectURL(url)
          })
        }
    },
    // 监听pagesize
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.params.pageSize = newSize
      this.getList()
    },
    // 页码值发生切换
    handleCurrentChange(newPage) {
      // console.log(newPage)
      // console.log('将分页保存到session中')
      // const page = newPage
      // sessionStorage.setItem('page', page)
      this.params.currentPage = newPage
      this.getList()
    },
    // 清空
    deleteAddForm(formName) {
      this.params.guaranteeId=''
      this.params.etpName = ''
      this.params.projectName=''
      this.params.projectCode=''
      this.params.guaranteeContractNo=''
      this.params.start=undefined
      this.params.end=undefined
      this.dateStart=undefined
      this.dateEnd=undefined
      this.params.currentPage=1
      this.params.pageSize=10
      this.getList()
    },
    tableRowClassName({ row }) {
      if (row.isDownload === 1) {
        return 'success-row'
      } else {
        return 'warning-row'
      }
    },
    getRowKey(row) {
      // console.log('标识的id')
      // console.log(row.id)
      return row.guid
    },
    // 获取选中的id
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.ids = []
      for (let i = 0; i <= this.multipleSelection.length - 1; i++) {
        this.ids.push(this.multipleSelection[i].gid)
      }
      console.log(val)
    }
  }
}
</script>

<style>
 .el-table .warning-row {
    /* background: #f35565; */
    border: 1px solid #f35565;
    /* color:#ffffff; */
  }

  .el-table .success-row {
    background: #ffffff;
  }
  .upload{
    color:red;
  }
</style>