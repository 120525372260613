<template>
  <div class="app-container">
    <el-row :gutter="20">
      <!--主数据字典-->
      <el-col>
        <el-form
          :model="queryParams"
          ref="queryForm"
          :inline="true"
          v-show="showSearch"
          label-width="68px"
        >
          <el-form-item label="名称" prop="itemName">
            <el-input
              v-model="queryParams.itemName"
              placeholder="请输入名称关键词"
              clearable
              size="small"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item label="数据标识" prop="itemKey">
            <el-input
              v-model="queryParams.itemKey"
              placeholder="请输入数据标识"
              clearable
              size="small"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item label="所属企业" prop="enterpriseid">
            <el-input
              v-model="queryParams.enterpriseSecret"
              placeholder="请输入企业标识"
              clearable
              size="small"
            />
          </el-form-item>
          <el-form-item label="所属系统" prop="appId">
            <el-input
              v-model="queryParams.appId"
              placeholder="请输入AppId"
              clearable
              size="small"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              v-preventReClick
              @click="handleQuery"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
              >重置</el-button
            >
          </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button
              type="primary"
              plain
              icon="el-icon-plus"
              size="mini"
              v-preventReClick
              @click="handleAdd"
              >新增</el-button
            >
          </el-col>
          <right-toolbar
            :showSearch.sync="showSearch"
            @queryTable="getList"
          ></right-toolbar>
        </el-row>

        <el-table
          v-loading="loading"
          :data="dataList"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            label="主键"
            align="center"
            key="guid"
            prop="guid"
            width="280"
          />
          <el-table-column
            label="中文名称"
            align="center"
            key="itemName"
            prop="itemName"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="英文名称"
            align="center"
            key="itemNameEn"
            prop="itemNameEn"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="数据标识"
            align="center"
            key="itemKey"
            prop="itemKey"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="所属数据类型"
            align="center"
            key="typeName"
            prop="typeName"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="所属系统"
            align="center"
            key="appId"
            prop="appId"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="所属企业"
            align="center"
            key="enterpriseSecret"
            prop="enterpriseSecret"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="创建时间"
            align="center"
            prop="createTime"
            width="160"
          >
            <template slot-scope="scope">
              <span>{{ $parseTime(scope.row.created) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="160"
            class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                v-preventReClick
                @click="handleUpdate(scope.row)"
                >修改</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                v-preventReClick
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.currentPage"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </el-col>
    </el-row>

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="open"
      width="1000px"
      append-to-body
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="所属企业" prop="enterpriseSecret">
              <el-select
                v-loading="etploading"
                v-model="form.enterpriseSecret"
                @change="getEtpSystem"
              >
                <el-option
                  v-for="item in enterpriseOptions"
                  :key="item.code"
                  :value="item.code"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="form.enterpriseSecret">
          <el-col :span="24">
            <el-form-item label="所属系统" prop="appId">
              <el-select
                v-loading="etpSystemloading"
                v-model="form.appId"
                @change="getDataType"
              >
                <el-option
                  v-for="item1 in enterpriseSystemOptions"
                  :key="item1.code"
                  :value="item1.code"
                  :label="item1.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="form.appId">
          <el-col>
            <el-form-item label="数据类型" prop="typeGuid">
              <el-select
                v-model="form.typeGuid"
                placeholder="请选择"
                v-loading="typeGuidLoading"
                @change="getTypeTree"
              >
                <el-option
                  v-for="item2 in dataTypeOptions"
                  :key="item2.guid"
                  :label="item2.typeName"
                  :value="item2.guid"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="(form.guid || form.typeGuid) && itemTreeParent">
          <el-col>
            <el-form-item label="父级" prop="parentGuid">
              <treeselect
                v-model="form.parentGuid"
                :options="itemTreeData"
                :normalizer="normalizer"
                :show-count="true"
                v-if="itemTreeParent"
                placeholder=""
                style="width: 240px"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="form.guid || form.typeGuid">
          <el-col>
            <el-form-item label="中文名称" prop="itemName">
              <el-input v-model="form.itemName" placeholder="请输入中文名称" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="form.guid || form.typeGuid">
          <el-col>
            <el-form-item label="英文名称" prop="itemNameEn">
              <el-input
                v-model="form.itemNameEn"
                placeholder="请输入英文名称"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="form.guid || form.typeGuid">
          <el-col>
            <el-form-item label="数据标识" prop="itemKey">
              <el-input v-model="form.itemKey" placeholder="请输入类型标识" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="form.guid || form.typeGuid">
          <el-col>
            <el-form-item label="排序" prop="itemOrder">
              <el-input v-model="form.itemOrder" placeholder="请输入排序" />
            </el-form-item>
          </el-col>
        </el-row>

        
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm" v-preventReClick
          >确 定</el-button
        >
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listEnterprise } from '@/api/system/enterprise'
import { listApplication } from '@/api/system/application'
import { list, del, get, update, add } from '@/api/maindata/dataitem'
import { listDict, list as typeList ,get as typeGet} from '@/api/maindata/datatype'
import { getMenuPriByMenuId } from '@/api/menu/index'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  name: 'dataitem',
  components: { Treeselect },
  data() {
    return {
      menuPriPath: [],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      masterData: [], //数据类型
      itemTreeData: [], //树形item
      itemTreeParent: false,
      typeGuidLoading: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      dataList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 是否显示弹出层（数据权限）
      openDataScope: false,
      menuExpand: false,
      menuNodeAll: false,
      deptExpand: true,
      deptNodeAll: false,
      // 日期范围
      dateRange: [],
      // 状态数据字典
      statusOptions: [],
      //分类列表
      categoryOptions: [],
      enterpriseOptions: [], //企业信息列表
      enterpriseSystemOptions: [], //企业信息对应的系统
      dataTypeOptions: [], //数据类型
      etploading: false,
      etpSystemloading: false,
      // 查询参数
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        itemName: undefined,
        ishow: 1,
        itemKey: undefined,
        enterpriseSecret: undefined,
        appId: undefined
      },
      // 表单参数
      form: {},
      // typeGuid:undefined,
      // itemGuid:undefined,
      // enterpriseSecret:undefined,
      // appId:undefined,
      defaultProps: {
        children: 'childrens',
        label: 'name'
      },
      // 表单校验
      rules: {
        itemName: [
          { required: true, message: '中文名称不能为空', trigger: 'blur' }
        ],
        itemKey: [{ required: true, message: '数据标识', trigger: 'blur' }],
        enterpriseSecret: [
          { required: true, message: '所属企业标识', trigger: 'change' }
        ],
        appId: [
          { required: true, message: '所属系统AppId', trigger: 'change' }
        ],
        typeGuid: [
          { required: true, message: '数据类型不能为空', trigger: 'blur' }
        ],
        itemOrder: [
          { required: true, message: '排序号不能为空', trigger: 'change' }
        ]
      }
    }
  },
  created() {
    this.getEnterpriseList()
    this.getRolePriList()
    this.getList()
  },
  methods: {
    normalizer(node) {
      if (node.childrens && !node.childrens.length) {
        delete node.childrens
      }
      return {
        id: node.guid,
        label: node.itemName,
        children: node.childrens
      }
    },
    /** 查询角色列表权限 */
    getRolePriList() {
      var data = {
        menuGuid: this.$route.query.menuGuid
      }
      getMenuPriByMenuId(data).then((res) => {
        if (res.data.success) {
          for (var i = 0; i < res.data.result.length; i++) {
            this.menuPriPath.push(res.data.result[i].privilegeAction)
          }
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    /**获取企业列表 */
    async getEnterpriseList() {
      //debugger
      this.etploading = true
      const data = {
        currentPage: undefined,
        pageSize: undefined
      }
      this.enterpriseOptions = []
      listEnterprise(data).then((res) => {
        this.etploading = false
        if (res.data.success) {
          let enterpriseList = res.data.result.records
          enterpriseList.forEach((element) => {
            let etp = {}
            etp.code = element.enterpriseSecret
            etp.name = element.name
            this.enterpriseOptions.push(etp)
            return this.enterpriseOptions
          })
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    //获取企业系统
    async getEtpSystem() {
      this.etpSystemloading = true
      this.enterpriseSystemOptions = []
      // if(this.form.enterpriseSecret)this.enterpriseSecret=this.form.enterpriseSecret
      const data = {
        currentPage: 1,
        pageSize: 100,
        enterpriseSecret: this.form.enterpriseSecret
      }
       listApplication(data).then((res) => {
        this.etpSystemloading = false
        if (res.data.success) {
          let appList = res.data.result.records
          appList.forEach((element) => {
            let app = {}
            app.code = element.appid
            app.name = element.name
            this.enterpriseSystemOptions.push(app)
          })
          return this.enterpriseSystemOptions
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    // 获取所有数据类型
    async getDataType() {
      // if(!this.form.enterpriseSecret||!this.form.appId){
      //    return this.$msgError("企业标识或appId无效.");
      // }
      this.dataTypeOptions = []
      // this.form.typeGuid=''
      const data = {
        currentPage: null,
        pageSize: null,
        ishow: 1,
        enterpriseSecret: this.form.enterpriseSecret,
        appId: this.form.appId
      }
      this.typeGuidLoading = true
      typeList(data).then((res) => {
        this.typeGuidLoading = false
        if (res.data.success) {

          this.dataTypeOptions = res.data.result.records
          return this.dataTypeOptions
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    // 数据类型改变
    async getTypeTree() {
      debugger
      if (!this.form.typeGuid) return
      const data={
        guid:this.form.typeGuid
      }
      typeGet(data).then(res=>{
        let obj=res.data.result
        if (obj.isTree == 1) {
          this.itemTreeData = []
          const params = {
            typeKey: obj.typeKey
          }
          listDict(params).then((res) => {
              this.itemTreeData = res.data.result
              this.itemTreeParent = true
          })
        } else {
          this.itemTreeParent = false
          this.itemTreeData = []
        }
      })
    },
    // 获取列表
    getList() {
      this.loading = true
      list(JSON.stringify(this.queryParams)).then((res) => {
        if (res.data.success) {
          this.dataList = res.data.result.records
          this.total = res.data.result.total
          this.loading = false
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 取消按钮（数据权限）
    cancelDataScope() {
      this.openDataScope = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {}
      this.$resetForm('form')
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.currentPage = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = []
      this.$resetForm('queryForm')
      this.handleQuery()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.roleId)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    

    /** 新增按钮操作 */
    handleAdd() {
      this.reset()
      this.open = true
      this.form.isTree = '0'
      this.title = '添加数据'
    },
    /** 修改按钮操作 */
    async handleUpdate(row) {
      debugger
      this.reset()
      this.title = '修改数据'
      this.open = true
      this.form = JSON.parse(JSON.stringify(row))
      console.log(row)
      // this.form.enterpriseSecret = row.enterpriseSecret
      // this.form.appId = row.appId
      // this.form.typeGuid = row.typeGuid
      // this.form.guid=row.guid
      // this.form.itemName = row.itemName
      // this.form.itemNameEn = row.itemNameEn
      // this.form.itemKey = row.itemKey
      // this.form.itemOrder = row.itemOrder
      // this.form.parentGuid = row.parentGuid
      await this.getEnterpriseList()
      await this.getEtpSystem()
      await this.getDataType()
      await this.getTypeTree()
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.form.guid != undefined) {
            update(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('修改成功')
                this.open = false
                this.getList()
              } else {
                this.$msgError(res.data.message)
              }
            })
          } else {
            this.form.ishow = 1
            add(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('新增成功')
                this.open = false
                this.getList()
              } else {
                this.$msgError(res.data.message)
              }
            })
          }
        }
      })
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm(
        '是否确认删除名称为"' + row.typeName + '"的数据项?',
        '警告',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(function () {
          let data = {
            guid: row.guid
          }
          return del(data)
        })
        .then((res) => {
          if (res.data.success) {
            this.getList()
            this.$msgSuccess('删除成功')
          } else {
            this.$msgError(res.data.message)
          }
        })
    }
  }
}
</script>
