<template>
  <div class="app-container">
    <el-form
      ref="params"
      :model="params"
      :inline="true"
    >
      <el-form-item>
        <el-input
          v-model="params.title"
          class="input"
          size="mini"
          style="width:150px"
          placeholder="标题"
          clearable
          @clear="getList"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="params.content"
          class="input"
          size="mini"
          style="width:150px"
          placeholder="内容"
          clearable
          @clear="getList"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-search"
          @click="getList"
        >查询</el-button>
        <el-button size="mini" type="primary" icon="el-icon-refresh-left" @click="deleteAddForm">重置</el-button>
      </el-form-item>
    </el-form>
      <br>
    <el-table
      ref="multipleTable"
      size="mini"
      fit
      :data="tableData"
      border
      @selection-change="handleSelectionChange"
      v-loading="tableLoading"
    >
      <el-table-column type="selection" width="55" align="center" fixed="" />
      <el-table-column type="index" :index="indexMethod" label="序号" width="50" align="center" fixed=""  />
      <el-table-column prop="userName" label="查询人" align="center" fixed="" />
      <el-table-column prop="title" label="请求内容" align="center" />
      <el-table-column prop="content" label="查询结果" align="center" />
      <el-table-column prop="created" label="查询时间" align="center">
         <template slot-scope="scope">{{$parseTime(scope.row.created)}}</template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
      <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="params.currentPage"
          :limit.sync="params.pageSize"
          @pagination="getList"
        />
  </div>
</template>

<script>
import { getMenuPriByMenuId } from '@/api/menu/index'
import { getOperatorLog } from '@/api/guarantee/log'
export default {
  name: 'operatorLogList',
  data() {
    return {
            menuPriPath:[],//菜单角色权限
      jbVisible:false,
      fileName: '压缩包',
      jbOptions:[],
      invoiceCategoryOptions: [],
      invoiceStatusOptions:[],
      multipleSelection: [],
      ids: [],
      tableLoading:false,
      id: '',
      name: '',
      ziptype: '',
      params: {
        'title':undefined,
        'content':undefined,
        'currentPage': 1,
        'pageSize': 10
      },
      total: 0,
      windowHeight:0,
      // 假数据
      tableData: []
    }
  },
  created() {
     this.getRolePriList()
    this.getList()
  },
  methods: {
    getRolePriList() {
      var data = {
        menuGuid: this.$route.query.menuGuid
      }
      getMenuPriByMenuId(data).then((res) => {
        if (res.data.success) {
          for (var i = 0; i < res.data.result.length; i++) {
            this.menuPriPath.push(res.data.result[i].privilegeAction)
          }
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    initInvoiceCategory(){
      listInvoiceCategory().then(res => {
         if(res.data.success){
           this.invoiceCategoryOptions=res.data.result;
         }
      })
    },
    initInvoiceStatus(){
      listInvoiceStatus().then(res => {
         if(res.data.success){
           this.invoiceStatusOptions=res.data.result;
         }
      })
    },
    indexMethod(index){
        return (this.params.currentPage-1)*this.params.pageSize+index+1;
      },

    // 获取列表页请求
    getList() {
      if(this.tableLoading)return;
      this.tableLoading=true;
      getOperatorLog(this.params).then(res => {
         this.tableLoading=false
         this.tableData = res.data.result.records
        this.total = res.data.result.total
      })
    },
    // 监听pagesize
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.params.pageSize = newSize
      this.getList()
    },
    // 页码值发生切换
    handleCurrentChange(newPage) {
      // console.log(newPage)
      // console.log('将分页保存到session中')
      // const page = newPage
      // sessionStorage.setItem('page', page)
      this.params.currentPage = newPage
      this.getList()
    },
    // 清空
    deleteAddForm(formName) {
      this.params.applicant = undefined
      this.params.telephone=undefined
     
      this.params.status=undefined
      this.params.invoiceCategory = undefined
      this.params.currentPage=1
      this.params.pageSize=10
      this.getList()
    },
    tableRowClassName({ row }) {
      if (row.isDownload === 1) {
        return 'success-row'
      } else {
        return 'warning-row'
      }
    },
    getRowKey(row) {
      // console.log('标识的id')
      // console.log(row.id)
      return row.guid
    },
    // 获取选中的id
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.ids = []
      for (let i = 0; i <= this.multipleSelection.length - 1; i++) {
        this.ids.push(this.multipleSelection[i].guid)
      }
      console.log(val)
    },
    //确认退款操作
    again_invoice(row){
       this.$confirm('此操作将发送系统自动开票, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
            console.log('sendKp')
            const data={
              guid:row.guid
            }
            this.tableLoading=true
            sendKp(data).then(res=>{
                console.log(res)
                this.tableLoading=false;
                if (!res.data.success) {
                  this.$msgError(res.data.message)
                } else {
                  this.$msgSuccess('发送开票请求成功')
                  this.getList()
                }
                
            })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消开票'
        })
      })
    },

  }
}
</script>

<style>
 .el-table .warning-row {
    /* background: #f35565; */
    border: 1px solid #f35565;
    /* color:#ffffff; */
  }

  .el-table .success-row {
    background: #ffffff;
  }
  .upload{
    color:red;
  }
</style>