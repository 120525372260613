<template>
  <div>
    <div class="select_box">
      <div class="select_list">
        <el-select
          v-model="originAddr.province"
          placeholder="-选择省份-"
          @change="getListCityBtn"
        >
          <el-option
            v-for="item in provinceOptions"
            :key="item.provinceCode"
            :label="item.provinceName"
            :value="item.provinceCode"
          ></el-option>
        </el-select>
      </div>
      <div class="select_list">
        <el-select
          v-model="originAddr.city"
          placeholder="-选择城市-"
          @change="getListAreaBtn"
        >
          <el-option
            v-for="item in cityOptions"
            :key="item.cityCode"
            :label="item.cityName"
            :value="item.cityCode"
          ></el-option>
        </el-select>
      </div>
      <div class="select_list">
        <el-select
          v-model="originAddr.area"
          placeholder="-选择区/县-"
          @change="getListStreetBtn"
        >
          <el-option
            v-for="item in areaOptions"
            :key="item.areaCode"
            :label="item.areaName"
            :value="item.areaCode"
          ></el-option>
        </el-select>
      </div>
      <!-- <div class="select_list">
        <el-select
          v-model="originAddr.street"
          placeholder="-选择街道/镇-"
          @change="getaddrBtn"
        >
          <el-option
            v-for="item in streetOptions"
            :key="item.streetCode"
            :label="item.streetName"
            :value="item.streetCode"
          ></el-option>
        </el-select>
      </div> -->
    </div>
    <div style="padding-top: 20px">
      <el-input v-model="originAddr.addr" placeholder="具体地址"></el-input>
    </div>
  </div>
</template>
<script>
import {
  listProvince,
  listCity,
  listArea,
  listStreet
} from '@/api/maindata/address'
export default {
  data() {
    return {
      originAddr: {},
      provinceOptions: [],
      cityOptions: [],
      areaOptions: [],
      streetOptions: []
    }
  },
  name: 'mainDataAddr',
  props: {
    etpRegAddress: {
      type: Object
    }
  },
  mounted() {
    this.initValue()
    this.getListProvince()
  },
  created() {
    // this.getListProvince()
  },
  methods: {
    initValue() {
      console.log(this.etpRegAddress)
      this.originAddr = this.etpRegAddress
    },
    getListProvince() {
      const data = {
        currentPage: 1,
        pageSize: 5000
      }
      listProvince(data).then((res) => {
        if (res.data.success) {
          this.provinceOptions = res.data.result.records
          if (this.originAddr.province) {
            this.getListCity()
          }
        } else {
          console.log(res.data.message)
        }
      })
    },
    getListCityBtn() {
      // 点击省份
      this.originAddr.city = ''
      this.originAddr.area = ''
      this.originAddr.street = ''
      this.originAddr.addr = ''
      this.getListCity()
    },
    getListCity() {
      if (!this.originAddr.province) return
      const data = {
        currentPage: 1,
        pageSize: 5000,
        provinceCode: this.originAddr.province
      }
      listCity(data).then((res) => {
        if (res.data.success) {
          this.cityOptions = res.data.result.records
          if (this.originAddr.city) {
            this.getListArea()
          }
        } else {
          console.log(res.data.message)
        }
      })
    },
    getListAreaBtn() {
      // 点击城市
      this.originAddr.area = ''
      this.originAddr.street = ''
      this.originAddr.addr = ''
      this.getListArea()
    },
    getListArea() {
      if (!this.originAddr.city) return
      const data = {
        currentPage: 1,
        pageSize: 5000,
        cityCode: this.originAddr.city
      }
      listArea(data).then((res) => {
        if (res.data.success) {
          this.areaOptions = res.data.result.records
          if (this.originAddr.area) {
            this.getListStreet()
          }
        } else {
          console.log(res.data.message)
        }
      })
    },
    getListStreetBtn() {
      // 点击区县
      this.originAddr.street = ''
      this.originAddr.addr = ''
      this.getListStreet()
    },
    getListStreet() {
      if (!this.originAddr.area) return
      const data = {
        currentPage: 1,
        pageSize: 5000,
        areaCode: this.originAddr.area
      }
      listStreet(data).then((res) => {
        if (res.data.success) {
          this.streetOptions = res.data.result.records
        } else {
          console.log(res.data.message)
        }
      })
    },
    getaddrBtn() {
      // 点击街道
      this.originAddr.addr = ''
    }
  },
  watch: {
    originAddr: {
      deep: true, // 深度观察对象的变化
      handler(newObj) {
        this.$emit('addr-sent', newObj) // 触发自定义事件，并将新的对象传递给父组件
      }
    },
    etpRegAddress(newObj) {
      this.originAddr = newObj
      this.getListProvince()
    }
  }
}
</script>
<style scoped lang="scss">
.select_box {
  display: flex;

  .select_list {
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }
  }
}
</style>
