import { request, post } from '@/plugins/request';
// 查询列表
export function list(data) {
    return post('/guarantee/v0.1/guarantee/user/tu/list', data);
}
// 查询详细
export function detail(data) {
    return post('/guarantee/v0.1/guarantee/user/tu/detail', data);
}
// 更新用户
export function update(data) {
    return post('/guarantee/v0.1/guarantee/user/tu/update', data);
}