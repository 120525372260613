import { request, post,downloadNew } from '@/plugins/request';
// 查询退款列表
export function list(data) {
    return post('/guarantee/v0.1/guarantee/order/tro/list', data);
}

// 退款状态列表
export function listRefundOrderStatus(data) {
    return post('/guarantee/v0.1/guarantee/order/tro/listRefundOrderStatus', data);
}

// 确认退款
export function confirmRefund(data) {
    return post('/guarantee/v0.1/guarantee/order/tro/confirmRefund', data);
}
// 手动退款
export function manualRefund(data) {
    return post('/guarantee/v0.1/guarantee/order/tro/manualRefund', data);
}
/**
 * 导出申请退款明细
 * @param {*} data 
 * @returns 
 */
export function exportRefundList(data) {
    return downloadNew('/guarantee/v0.1/guarantee/order/tro/exportRefundList', data);
}
