<template>
  <div class="app-container">
    <el-row :gutter="20">
      <!--用户数据-->
      <el-col>
        <el-form
          :model="queryParams"
          ref="queryForm"
          :inline="true"
          v-show="showSearch"
          label-width="68px"
        >
          <el-form-item label="系统名称" prop="name">
            <el-input
              v-model="queryParams.name"
              placeholder="请输入系统名称"
              clearable
              size="small"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item label="appid" prop="appid">
            <el-input
              v-model="queryParams.appid"
              placeholder="请输入appid"
              clearable
              size="small"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item label="企业标识" prop="enterpriseSecret">
            <el-input
              v-model="queryParams.enterpriseSecret"
              placeholder="请输入appid"
              clearable
              size="small"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              v-preventReClick
              @click="handleQuery"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
              >重置</el-button
            >
          </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button
              type="primary"
              plain
              icon="el-icon-plus"
              size="mini"
              v-preventReClick
              @click="handleAdd"
              v-if="menuPriPath.indexOf('add') >= 0"
              >新增</el-button
            >
          </el-col>
          <right-toolbar
            :showSearch.sync="showSearch"
            @queryTable="getList"
          ></right-toolbar>
        </el-row>

        <el-table
          v-loading="loading"
          :data="siteList"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            label="主键"
            align="center"
            key="guid"
            prop="guid"
            width="230"
          />
          <el-table-column
            label="系统名称"
            align="center"
            key="name"
            prop="name"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="appid"
            align="center"
            key="appid"
            prop="appid"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="企业标识"
            align="center"
            key="enterpriseSecret"
            prop="enterpriseSecret"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="排序"
            align="center"
            key="orderNum"
            prop="orderNum"
            width="120"
          />

          <el-table-column
            label="创建时间"
            align="center"
            prop="createTime"
            width="160"
          >
            <template slot-scope="scope">
              <span>{{ $parseTime(scope.row.created) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="160"
            class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                v-preventReClick
                @click="handleUpdate(scope.row)"
                v-if="menuPriPath.indexOf('edit') >= 0"
                >修改</el-button
              >
              <el-button
                v-if="
                  menuPriPath.indexOf('delete') >= 0 && scope.row.uGuid !== 1
                "
                size="mini"
                type="text"
                icon="el-icon-delete"
                v-preventReClick
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.currentPage"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </el-col>
    </el-row>

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row>
          <el-col>
            <el-form-item label="企业" prop="enterpriseSecret">
               <el-select v-loading="etploading"  v-model="form.enterpriseSecret">
                    <el-option v-for="item in enterpriseOptions" :key="item.code"  :value="item.code" :label="item.name"></el-option>
                  </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="AppName" prop="name">
              <el-input v-model="form.name" placeholder="请输入AppName" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="AppId" prop="appid">
              <el-input v-model="form.appid" placeholder="请输入AppId" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="AppSecret" prop="appSecret">
              <el-input
                v-model="form.appSecret"
                placeholder="请输入AppSecret"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="排序" prop="orderNum">
              <el-input
                v-model="form.orderNum"
                placeholder="排序"
                maxlength="11"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm" v-preventReClick
          >确 定</el-button
        >
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listEnterprise
} from '@/api/system/enterprise'
import {
  listApplication,
  getApplication,
  addApplication,
  updateApplication,
  delApplication
} from '@/api/system/application'
import { getMenuPriByMenuId } from '@/api/menu/index'
export default {
  name: 'app',
  data() {
    return {
      menuPriPath: [],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 角色表格数据
      siteList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 是否显示弹出层（数据权限）
      openDataScope: false,
      menuExpand: false,
      menuNodeAll: false,
      deptExpand: true,
      deptNodeAll: false,
      // 日期范围
      dateRange: [],
      // 状态数据字典
      statusOptions: [],
      enterpriseOptions:[],//企业信息列表
      etploading:false,
      // 查询参数
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        name: undefined,
        appid: undefined,
        enterpriseSecret: undefined
      },
      // 表单参数
      form: {},
      defaultProps: {
        children: 'childrens',
        label: 'name'
      },
      // 表单校验
      rules: {
        name: [
          { required: true, message: '系统名称不能为空', trigger: 'blur' }
        ],
        appid: [{ required: true, message: '系统id不能为空', trigger: 'blur' }],
        appSecret: [
          { required: true, message: '系统密钥不能为空', trigger: 'blur' }
        ],
        enterpriseSecret: [
          { required: true, message: '企业标识不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getList()
    this.getRolePriList()
    this.getEnterpriseList()
  },
  methods: {
    /** 查询角色列表 */
    getRolePriList() {
      debugger
      var data = {
        menuGuid: this.$route.query.menuGuid
      }
      getMenuPriByMenuId(data).then((res) => {
        if (res.data.success) {
          for (var i = 0; i < res.data.result.length; i++) {
            this.menuPriPath.push(res.data.result[i].privilegeAction)
          }
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    /**获取企业列表 */
    getEnterpriseList() {
      //debugger
      this.etploading = true
      const data={
                currentPage: 1,
        pageSize: 100
      }
      listEnterprise(data).then((res) => {
        this.etploading=false
        if (res.data.success) {
          let enterpriseList= res.data.result.records
          enterpriseList.forEach(element => {
            let etp={}
            etp.code=element.enterpriseSecret
            etp.name=element.name
            this.enterpriseOptions.push(etp)
          })
          console.log(this.enterpriseOptions)
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    getList() {
      this.loading = true
      listApplication(JSON.stringify(this.queryParams)).then((res) => {
        if (res.data.success) {
          this.siteList = res.data.result.records
          this.total = res.data.result.total
          this.loading = false
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 取消按钮（数据权限）
    cancelDataScope() {
      this.openDataScope = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        guid: undefined,
        name: undefined,
        orderNum: undefined
      }
      this.$resetForm('form')
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.currentPage = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = []
      this.$resetForm('queryForm')
      this.handleQuery()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.roleId)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },

    /** 新增按钮操作 */
    handleAdd() {
      this.reset()
      this.open = true
      this.title = '添加系统'
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset()
      var data = {
        guid: row.guid
      }
      getApplication(data).then((res) => {
        if (res.data.success) {
          this.form = res.data.result
          this.open = true
          this.title = '修改系统'
        } else {
          this.$msgError(res.data.message)
        }
      })
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.form.guid != undefined) {
            updateApplication(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('修改成功')
                this.open = false
                this.getList()
              } else {
                this.$msgError(res.data.message)
              }
            })
          } else {
            addApplication(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('新增成功')
                this.open = false
                this.getList()
              } else {
                this.$msgError(res.data.message)
              }
            })
          }
        }
      })
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm(
        '是否确认删除系统名称为"' + row.name + '"的数据项?',
        '警告',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(function () {
          let data = {
            guid: row.guid
          }
          return delApplication(data)
        })
        .then((res) => {
          if (res.data.success) {
            this.getList()
            this.$msgSuccess('删除成功')
          } else {
            this.$msgError(res.data.message)
          }
        })
    },
    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams
      this.$confirm('是否确认导出所有角色数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(function () {
          return exportRole(queryParams)
        })
        .then((res) => {
          if (res.data.success) {
            this.$download(res.msg)
          } else {
            this.$msgError(res.data.message)
          }
        })
    }
  }
}
</script>
