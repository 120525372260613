<template>
  <div class="app-container">
    <div style="width: 70%; margin: 20px auto">
      <el-steps :active="isPage">
        <el-step title="项目相关信息" description=""></el-step>
        <el-step title="等待审核" description=""></el-step>
        <el-step title="付款并提供签约资料" description=""></el-step>
        <el-step title="保函开立" description=""></el-step>
      </el-steps>
    </div>
    <div class="applyFor" v-loading="dataLoading">
      <div
        v-if="formModel.status == '99'"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          padding: 10px 0;
        "
      >
        <i
          style="font-size: 26px; margin-right: 10px"
          class="el-icon-document"
        ></i>
        <p>您提交的信息正在审核中，请耐心等待</p>
      </div>
      <el-form
        style="background: #fff"
        ref="form"
        size="small"
        :model="formModel"
        class="demo-ruleForm"
      >
        <div
          style="
            line-height: 40px;
            margin-bottom: 20px;
            border-bottom: 1px solid #eee;
            font-weight: 700;
          "
        >
          订单信息
        </div>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="订单状态：">
              <span class="el_item_text">{{ formModel.statusName }}</span>
            </el-form-item>
          </el-col>
           <el-col :span="12" v-if="formModel.status != '99'">
            <el-form-item label="付款金额（人民币）：">
              <span class="el_item_text"
                >{{ formModel.guaranteePayMoney||'--' }} 元
              <span>{{ formModel.isSubmitPayVoucher ? ' （ 已提交 ）' : '' }}</span></span
              >
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请时间：">
              <span class="el_item_text">{{ formModel.created }}</span>
            </el-form-item>
          </el-col>
           <el-col :span="12">
            <el-form-item label="保函性质：">
              <span class="el_item_text">{{ formModel.guaranteeTypeName }}</span>
            </el-form-item>
          </el-col>
           <el-col :span="12">
            <el-form-item label="出函机构：">
              <span class="el_item_text">{{ formModel.returnGuaranteeOrgName }}</span>
            </el-form-item>
          </el-col>
           <el-col :span="12">
            <el-form-item label="保函分类：">
              <span class="el_item_text">{{ formModel.guaranteeCategoryName }}</span>
            </el-form-item>
          </el-col>
          <el-col
            :span="12"
          >
            <el-form-item label="付款凭证：">
               <span v-if="(formModel.status==='02' 
                || formModel.status==='03'
                || formModel.status==='04'
                || formModel.status==='05'
                || formModel.status==='06'
                )&&formModel.isSubmitPayVoucher" class="el_item_text">（ 已提交 ）</span>
              <el-button  class="optButton" v-if="formModel.status==='00'" type="primary" @click="etplegaPayClick"
                >{{
                formModel.isSubmitPayVoucher ? '已提交/去编辑' : '去提交'
              }}</el-button
              >
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="签约资料：">
                <span v-if="(formModel.status==='03'
                || formModel.status==='04'
                || formModel.status==='05'
                || formModel.status==='06'
                ) &&formModel.isFixSignDocs" class="el_item_text">（ 已提交 ）</span>
              <!-- 去编辑 -->
              <el-button v-if="formModel.status==='00' || formModel.status==='02'" class="optButton"  type="primary" @click="signUploadBtn">{{
                formModel.isFixSignDocs ? '已提交/去编辑' : '去提交'
              }}</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="formModel.status == '00'">
            <div style="border: 1px solid #eee; padding: 8px">
              <p>收款账户：北京银保惠通科技有限公司</p>
              <p>账号：110943409410902</p>
              <p>开户行：招商银行股份有限公司北京西二旗支行</p>
            </div>
          </el-col>
        </el-row>
        <div
          style="
            border-bottom: 1px solid #eee;
            font-weight: 700;
            margin-bottom: 10px;
            padding: 10px 0;
          "
        >
          投标企业信息
        </div>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="企业名称：">
              {{ formModel.etpName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="社会信用代码：">
              {{ formModel.etpUscc }}
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="注册资本：">
              {{ formModel.etpRegCapital }} 万元
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属行业：">
              {{ formModel.etpIndustryName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企业性质：">
              {{ formModel.etpPropertyName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="注册地址：">
              {{ formModel.etpAddrFullName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证件签发日期：">
              {{
                formModel.etpCertBeginDate + ' 至 ' + formModel.etpCertEndDate
              }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="营业执照：">
             <div style="display: flex; align-items: center;margin:-5px 0 0 0;">
                <uploadBtn
                v-model="formModel.etpIdcard"
                :limit="upload.limit"
                :viewImage="upload.viewImage"
              >
              </uploadBtn>
             </div>
            </el-form-item>
          </el-col>
        </el-row>
        <div
          style="
            border-bottom: 1px solid #eee;
            font-weight: 700;
            margin-bottom: 10px;
            padding: 10px 0;
          "
        >
          法人信息
        </div>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="法人姓名：">
              {{ formModel.etpLegal }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="法人证件类型："> 身份证 </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="法人身份证号：">
              {{ formModel.etpLegalIdcardCode }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="法人身份证有效期：">
              {{ formModel.etpLegalIdcardDateBegin }} -
              {{ formModel.etpLegalIdcardDateEnd }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="法人手机号：">
              {{ formModel.etpLegalPhone }}
            </el-form-item>
          </el-col>
          <el-col :span="12"
          >  <el-form-item label="法人正面证件照：">
             <div style="display: flex; align-items: center;margin:-5px 0 0 0;">
              <uploadBtn
                v-model="formModel.etpLegalIdcardPicZm"
                :limit="upload.limit"
                :viewImage="upload.viewImage"
              >
              </uploadBtn>
              </div></el-form-item>
          </el-col>
          <el-col :span="12"
          >  <el-form-item label="法人背面证件照：">
             <div style="display: flex; align-items: center;margin:-5px 0 0 0;">
              <uploadBtn
                v-model="formModel.etpLegalIdcardPicFm"
                :limit="upload.limit"
                :viewImage="upload.viewImage"
              >
              </uploadBtn>
              </div></el-form-item>
          </el-col>
        </el-row>
        <div
          style="
            border-bottom: 1px solid #eee;
            font-weight: 700;
            margin-bottom: 10px;
            padding: 10px 0;
          "
        >
          项目信息
        </div>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="项目名称：">
              <span class="el_item_text">{{ formModel.projectName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="项目编号：">
              <span class="el_item_text">{{ formModel.projectCode }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="担保金额（人民币）：">
              <span class="el_item_text">{{ formModel.guaranteeMoney }}元</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="保函受益人(甲方/招标放名称)：">
              <span class="el_item_text">{{ formModel.bidCompanyName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="经办人姓名：">
              <span class="el_item_text">{{ formModel.operator }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="经办人手机号：">
              <span class="el_item_text">{{ formModel.operatorPhone }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="保函有效期：">
              <span v-if="formModel.guaranteeDays" class="el_item_text"
                >{{ formModel.guaranteeDays / 30 }}个月</span
              >
              <span v-else class="el_item_text">{{
                formModel.guaranteeEndDate
              }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="保函邮寄地址：">
              {{ formModel.guaranteePostAddrFullName }}
            </el-form-item>
          </el-col>
          <el-col v-if="formModel.guaranteeDocument == '03'" :span="12">
            <el-form-item style="margin-top: -15px">
              <div
                style="display: flex; align-items: center; margin-top: -25px"
              >
                <span style="padding-top: 40px">选择的保函文本：</span>
                <uploadBtn
                  v-model="formModel.guaranteeDocumentExt"
                  :limit="upload.limit"
                  :viewImage="upload.viewImage"
                >
                </uploadBtn>
              </div>
            </el-form-item>
          </el-col>
          <el-col v-else :span="12">
            <el-form-item label="选择的保函文本">
              <div
                v-if="formModel.guaranteeDocument === '01'"
                style="
                  text-align: left;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                "
                @click="pdfBtn"
              >
                <img
                  style="width: 30px; height: 30px"
                  class="yulan_img"
                  src="../../../assets/img/Pdf.png"
                  alt=""
                />
                <span
                  style="font-size: 14px; padding-left: 15px; color: #3d8bfc"
                  >保函文本（通用）点击预览</span
                >
              </div>
              <div
                v-if="formModel.guaranteeDocument === '02'"
                style="
                  text-align: left;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                "
                @click="pdfBtn"
              >
                <img
                  style="width: 30px; height: 30px"
                  class="yulan_img"
                  src="../../../assets/img/Pdf.png"
                  alt=""
                />
                <span
                v-if="formModel.guaranteeCategory==='02'"
                  style="font-size: 14px; padding-left: 15px; color: #3d8bfc"
                  >保函文本（独立-见索即付）点击预览</span
                >
                <span
                v-else
                  style="font-size: 14px; padding-left: 15px; color: #3d8bfc"
                  >保函文本（独立）点击预览</span
                >
              </div>
            </el-form-item>
          </el-col>
          <el-col
            :span="12"
            v-if="formModel.status == '03' || formModel.status == '06'"
          >
            <el-form-item label="已开立保函：">
              <div
                style="
                  text-align: left;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                "
                @click="downClick"
              >
                <img
                  style="width: 30px; height: 30px"
                  src="../../../assets/img/Pdf.png"
                  alt=""
                />
                <span
                  style="font-size: 14px; padding-left: 15px; color: #3d8bfc"
                  >点击下载</span
                >
              </div>
            </el-form-item>
          </el-col>
         <el-col :span="12">
           <el-form-item label="中标通知书：">
               <div style="display: flex; align-items: center;margin:-5px 0 0 0;">
               <uploadBtn
                v-model="formModel.winBiderDocument"
                :limit="upload.limit"
                :viewImage="upload.viewImage"
              >
              </uploadBtn>
               </div>
           </el-form-item>
          </el-col>
         <el-col :span="12">
           <el-form-item label="招标文件：">
<div style="display: flex; align-items: center;margin:-5px 0 0 0;">
               <uploadBtn
                v-model="formModel.bidDocument"
                :limit="upload.limit"
                :viewImage="upload.viewImage"
              >
              </uploadBtn>
               </div>
           </el-form-item>
          </el-col>
         <el-col :span="12"
         >
         <el-form-item label="合同：">
             <div style="display: flex; align-items: center;margin:-5px 0 0 0;">
                <uploadBtn
                  v-model="formModel.bidContract"
                  :limit="upload.limit"
                  :viewImage="upload.viewImage"
                >
                </uploadBtn>
               </div>
         </el-form-item>
            
          </el-col>
          <el-col :span="24">
            <el-form-item label="我要给客服留言：">
              <span class="el_item_text">{{ formModel.remark }}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-dialog
      v-if="dialogVisibleEtplega"
      title=""
      :visible.sync="dialogVisibleEtplega"
      width="750px"
      :close-on-click-modal="false"
    >
      <payMsg
        @childEditBtn="childEditBtn"
        :guaranteeId="guaranteeId"
        :guaranteePayMoney="formModel.guaranteePayMoney"
      ></payMsg>
    </el-dialog>
  </div>
</template>

<script>
import { getDetailZtb, getZtbPay, getPayOrderApi } from '@/api/system/applyFor'
import { parseTime } from '@/utils/DateUtils'
import { detailUpload, downloadFile } from '@/api/user/file'
import payMsg from '@/components/payMsg.vue'
import uploadBtn from '@/components/uploadBtn/index.vue'
export default {
  components: { payMsg, uploadBtn },
  data() {
    return {
      isFixSignDocs: 0,
      dialogVisibleEtplega: false,
      fileId: '', // 是否提交付款凭证
      dataLoading: false,
      formModel: {},
      upload: {
        limit: 1,
        viewImage: true
      },
      isPage: 1, //
      timer: null, // 定时器
      dialogVisible: false,
      radio: 0,
      guaranteeId: '',
      etpRA: {},
      payData: null,
      payType: '00' // 支付方式 00:二维码支付;01:企业网银在线支付
    }
  },
  mounted() {
    this.isPage = this.$route.query.isPage
      ? Number(this.$route.query.isPage)
      : 1
    this.isFixSignDocs = this.$route.query.isFixSignDocs
    this.guaranteeId = this.$route.query.guaranteeId
    this.getDetailData()
  },
  methods: {
    //下载保函
downClick() {
      const data = {
        guid: this.formModel.returnGuarantee
      }
      this.dataLoading = true
      detailUpload(data).then((ress) => {
        this.dataLoading = false
        if (ress.data.success && ress.data.result) {
          console.log(ress.data.result)
          this.dataLoading = true
          downloadFile(data).then((res) => {
            this.dataLoading = false
            const content = res.data
            const blob = new Blob([content], { type: 'application/pdf' })
            const fileName = ress.data.result.fileName
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          })
        } else {
          this.$msgError('文件无效')
        }
      })
    },
    pdfBtn() {
        switch(this.formModel.guaranteeCategory){
         case '01':
            if (this.formModel.guaranteeDocument == '01') {
              window.open(
                'https://api.yinbaobiao.com/web/ybb/upload/document-doc/tb-common.pdf',
                '_blank'
              )
            } else if (this.formModel.guaranteeDocument == '02') {
              window.open(
                'https://api.yinbaobiao.com/web/ybb/upload/document-doc/tb-alone-guarantee.pdf',
                '_blank'
              )
            }
          break;
         case '02':
            if (this.formModel.guaranteeDocument == '01') {
              window.open(
                'https://api.yinbaobiao.com/web/ybb/upload/document-doc/guarantee-doc-ly-common.pdf',
                '_blank'
              )
            } else if (this.formModel.guaranteeDocument == '02') {
              window.open(
                'https://api.yinbaobiao.com/web/ybb/upload/document-doc/guarantee-doc-ly-jsjf.pdf',
                '_blank'
              )
            }
          break;
         case '03':
            if (this.formModel.guaranteeDocument == '01') {
              window.open(
                'https://api.yinbaobiao.com/web/ybb/upload/document-doc/guarantee-doc-zl-common.pdf',
                '_blank'
              )
            } else if (this.formModel.guaranteeDocument == '02') {
              window.open(
                'https://api.yinbaobiao.com/web/ybb/upload/document-doc/guarantee-doc-zl-alone.pdf',
                '_blank'
              )
            }
          break;
         case '04':
            if (this.formModel.guaranteeDocument == '01') {
              window.open(
                'https://api.yinbaobiao.com/web/ybb/upload/document-doc/guarantee-doc-yfk-common.pdf',
                '_blank'
              )
            } else if (this.formModel.guaranteeDocument == '02') {
              window.open(
                'https://api.yinbaobiao.com/web/ybb/upload/document-doc/guarantee-doc-yfk-alone.pdf',
                '_blank'
              )
            }
          break;
      }
    },
    signUploadBtn() {
      this.$router.push({
        path: '/applyForMore/signUpload',
        query: {
          guaranteeId: this.formModel.guid
        }
      })
    },
    getDetailData() {
      // 获取详情
      this.dataLoading = true
      getDetailZtb({ guaranteeId: this.guaranteeId }).then((res) => {
        this.dataLoading = false
        if (res.data.success) {
          this.getPayOrderApiData()
          // 企业信息
          this.formModel = res.data.result
          this.formModel.etpCertBeginDate = parseTime(
            this.formModel.etpCertBeginDate,
            '{y}-{m}-{d}'
          )
          this.formModel.etpCertEndDate = parseTime(
            this.formModel.etpCertEndDate,
            '{y}-{m}-{d}'
          )
          this.formModel = res.data.result
          this.formModel.bidEndDate = parseTime(
            this.formModel.bidEndDate,
            '{y}-{m}-{d}'
          )
          this.formModel.guaranteeBeginDate = parseTime(
            this.formModel.guaranteeBeginDate,
            '{y}-{m}-{d}'
          )
          this.formModel.guaranteeEndDate = parseTime(
            this.formModel.guaranteeEndDate,
            '{y}-{m}-{d}'
          )
          this.formModel.created = parseTime(
            this.formModel.created,
            '{y}-{m}-{d} {h}:{i}:{s}'
          )
        } else {
          this.$message.error(res.data.message)
        }
        this.contentLoading = false
      })
    },
    etplegaPayClick() {
      // 提交付款凭证
      this.dialogVisibleEtplega = true
    },
    //身份证号脱敏
    desensitizeIdCard(idCard){
        // 确保输入的是字符串
        idCard = String(idCard)
        // 保留前6位和后4位，其余用星号替换
        return idCard.replace(/^(.{6})(?:\d+)(.{4})$/, "$1*********$2")
    },
    childEditBtn() {
      this.dialogVisibleEtplega = false
      this.getDetailData()
    },
    getPayOrderApiData() {
      // 付款凭证回显
      getPayOrderApi({
        guaranteeId: this.guaranteeId
      }).then((res) => {
        if (res.data.success) {
          this.fileId = res.data.result.fileId
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    gobidbond() {
      this.$router.push('/bidBondAdmin')
    }
  }
}
</script>

<style scoped lang="scss">
.optButton{
  line-height: 10px;
}
.applyFor {
  width: 1200px;
  margin: 0 auto;
}
::v-deep .el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0 0;
}

.el_item_text {
  color: #000;
}

.red {
  color: #f00 !important;
}
.left_title {
  width: 1200px;
  margin: 20px auto;
  span {
    cursor: pointer;
    color: #3d8bfc;
  }
}
.applyFor {
  ::v-deep .el-upload {
    display: none;
  }
}
</style>
