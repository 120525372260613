import request from '@/plugins/request';
import { post, download } from '@/plugins/request';

// 上传文件
export function uploadFile(data) {
  return post('/store/v0.1/store/store/attach/upload', data)
}
// 查询上传文件
export function listUpload(data) {
  return post('/store/v0.1/store/store/attach/listUpload', data)
}
// 查询单个上传文件(new)
export function detailUpload(data) {
  return post('/store/v0.1/store/store/attach/detail', data);
}
// 下载文件
export function downloadFile(data) {
  return download('/store/v0.1/store/store/attach/download', data)
}
// 删除上传文件
export function delUpload(data) {
  return post('/store/v0.1/store/store/attach/delete', data);
}

// 预览上传文件
export function viewUpload(guid) {
  return service({
    url: '/store/v0.1/attachment/viewImgBase64?guid=' + guid,
    method: 'post'
  })
}