<template>
    <div v-if="dataType == 'singleSelect'">
        <el-select  size="mini" v-model="myValue" :placeholder="dataTitle">
            <el-option v-for="item in dataOptions" :key="item.guid" :label="item.itemName"
                :value="item.itemKey"></el-option>
        </el-select>
    </div>
    <div v-else-if="dataType == 'multSelect'">
        <el-select  size="mini" v-model="myValue" multiple :placeholder="dataTitle">
            <el-option v-for="item in dataOptions" :key="item.guid" :label="item.itemName"
                :value="item.itemKey"></el-option>
        </el-select>
    </div>
    <div v-else-if="dataType == 'checkbox'">
        <el-checkbox-group size="mini" v-model="myValue">

            <el-checkbox v-for="item in dataOptions" :key="item.guid" :label="item.itemKey">{{ item.itemName
                }}</el-checkbox>
        </el-checkbox-group>
    </div>
    <div v-else-if="dataType === 'tree'">
        <el-cascader v-model="myValue" :props="cascaderProps" :options="dataOptions"></el-cascader>
    </div>
</template>
<script>
import { listDict } from '@/api/maindata/datatype'
export default {
    data() {
        return {
            dataOptions: [],
            myValue: undefined,
            cascaderProps: {
                value: 'guid', // 选项的值字段名
                label: 'itemName', // 选项的标签字段名
                children: 'childrens' // 选项的子选项字段名
            }
        }
    },
    name: 'maindata',
    props: {
        value: {
            type: String,
            default: ''
        },
        //选择框标题
        dataTitle:{
             type: String,
            default: '请选择'
        },
        //类型
        dataType: {
             type: String,
            default: ''
        },
        //数据类型Key
        dataKey: {
            type: String,
            default: ''
        },
    },
    mounted() {
        this.initValue();
    },
    created() {
        this.getListDict();
    },
    methods: {
        initValue() {
            if (this.dataType == 'singleSelect') {
                this.myValue = this.value;
            } else if (this.dataType == 'multSelect' || this.dataType == 'checkbox') {
                if (!this.value) {
                    this.myValue = [];
                } else {
                    this.myValue = this.value.split(',');
                }
            }
        },
        getListDict() {
            console.log(this.value)
            if (!this.dataKey) return;
            listDict({
                typeKey: this.dataKey
            }).then(res => {
                if (res.data.success) {
                    this.dataOptions = res.data.result;
                } else {
                    console.log(res.data.message)
                }
            })
        }
    },
    watch: {
        value(newValue) {
            // this.myValue = newValue;
            if (this.dataType == 'singleSelect') {
                this.myValue = newValue;
            } else if (this.dataType == 'multSelect' || this.dataType == 'checkbox' || this.dataType == 'tree') {
                if (!newValue) {
                    this.myValue = [];
                } else {
                    this.myValue = newValue.split(',');
                }

            }
        },
        myValue(newValue) {
            let a = '';
            if (this.dataType == 'multSelect' || this.dataType == 'checkbox' || this.dataType == 'tree') {
                a = newValue.join(',');
            } else {
                a = newValue;
            }
            this.$emit('input', a);
        }
    }
}
</script>
<style></style>