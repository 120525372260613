import { request, post } from '@/plugins/request';

/**
 * 省份
 * @param {*} data 
 * @returns 
 */
export function listProvince(data) {
    return post('/maindata/v0.1/maindata/maindata/province/list', data);
}
/**
 * 城市
 * @param {*} data 
 * @returns 
 */
export function listCity(data) {
    return post('/maindata/v0.1/maindata/maindata/city/list', data);
}

/**
 * 区/县
 * @param {*} data 
 * @returns 
 */
export function listArea(data) {
    return post('/maindata/v0.1/maindata/maindata/area/list', data);
}

/**
 * 街道/镇
 * @param {*} data 
 * @returns 
 */
export function listStreet(data) {
    return post('/maindata/v0.1/maindata/maindata/street/list', data);
}