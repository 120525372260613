<template>
  <div class="app-container">
    <el-form
      ref="params"
      :model="params"
      :inline="true"
    >
      <el-form-item>
        <el-input
          v-model="params.etpName"
          class="input"
          size="mini"
          style="width:150px"
          placeholder="投标企业"
          clearable
          @clear="getList"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="params.projectName"
          class="input"
          size="mini"
          style="width:150px"
          placeholder="项目名称"
          clearable
          @clear="getList"
        />
      </el-form-item>
      <!-- <el-form-item>
        <el-input
          v-model="name"
          :disabled="true"
          class="input"
          size="mini"
          style="width:150px"
          placeholder="所属项目"
        />
      </el-form-item> -->
      <el-form-item>
        <el-input
          v-model="params.guaranteeId"
          class="input"
          size="mini"
          style="width:150px"
          placeholder="保函唯一标识"
          clearable
          @clear="getList"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="params.payType"
          clearable
          size="mini"
          style="width:150px;"
          placeholder="支付方式"
          @clear="getList"
        >
          <el-option
            v-for="item in payTypeOptions"
            :key="item.code"
            :label="item.description"
            :value="item.code"
          />
        </el-select>
        </el-form-item>
        <el-form-item>
        <el-select
          v-model="params.status"
          clearable
          size="mini"
          style="width: 150px"
          placeholder="保函状态"
          @clear="getList"
        >
          <el-option
            v-for="item in guaranteeStatusOptions"
            :key="item.code"
            :label="item.description"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="params.guaranteeSource"
          clearable
          size="mini"
          style="width: 150px"
          placeholder="保函来源"
          @clear="getList"
        >
          <el-option
            v-for="item in enterpriseSystemOptions"
            :key="item.code"
            :label="item.description"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
        <el-form-item>
        <el-date-picker
          v-model="dateStart"
          value-format="yyyy-MM-dd HH:mm:ss"
          size="mini"
          type="datetime"
          placeholder="申请时间开始"
        />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="dateEnd"
          value-format="yyyy-MM-dd HH:mm:ss"
          size="mini"
          type="datetime"
          placeholder="申请时间结束"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-search"
          @click="getList"
        >查询</el-button>
        <el-button size="mini" type="primary" icon="el-icon-refresh-left" @click="deleteAddForm">重置</el-button>
        <el-button size="mini" type="primary" icon="el-icon-download" @click="exportFilter">按条件导出</el-button>
        <el-button size="mini" type="primary" icon="el-icon-download" @click="exportAll">全部导出</el-button>
      </el-form-item>
    </el-form>
      <br>
    <el-table
      ref="multipleTable"
      size="mini"
      fit
      :data="tableData"
      border
      :height="tableDataHeight"
      v-loading="tableLoading"
      :row-class-name="tableRowClassName"
      :row-key="getRowKey"
      @selection-change="handleSelectionChange"
    >
      <!-- <el-table-column type="selection" :reserve-selection="true" width="55" align="center" fixed="" /> -->
      <el-table-column type="index" label="序号" width="50" :index="indexMethod" align="center" fixed="" />
      <el-table-column prop="etpName" label="申请企业" align="center" />
      <el-table-column prop="projectName" label="项目名称" align="center"/>
      <el-table-column prop="projectCode" label="项目编码" align="center"/>
      <el-table-column prop="payMoney" label="支付金额(元)" align="center"/>
      <el-table-column prop="payTypeName" label="支付方式" align="center"/>
      <el-table-column prop="payTime" label="支付时间" align="center" >
          <template slot-scope="scope">{{$parseTime(scope.row.payTime)}}</template>
      </el-table-column>
      <el-table-column prop="created" label="申请时间" align="center" >
        <template slot-scope="scope">{{$parseTime(scope.row.created)}}</template>
      </el-table-column>
       <el-table-column prop="statusName" label="保函状态" align="center" />
        <el-table-column prop="guaranteeSourceName" label="保函来源" align="center" />
      <el-table-column prop="guaranteeId" label="保函唯一标识" align="center" />
    </el-table>
    <!-- 分页 -->
      <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="params.currentPage"
          :limit.sync="params.pageSize"
          @pagination="getList"
        />
  </div>
</template>

<script>
import {
  listApplication
} from '@/api/system/application'
import {
  getGuaranteeStatus
} from '@/api/guarantee/guarantee'
import { listIncome,listPayTypeStatus,exportIncome} from '@/api/guarantee/finance'
export default {
  name: 'RefundList',
  data() {
    return {
      jbVisible:false,
      fileName: '压缩包',
      jbOptions:[],
      payTypeOptions: [],
      multipleSelection: [],
      ids: [],
      tableLoading:false,
      id: '',
      name: '',
      ziptype: '',
      tableDataHeight:0,
      dateStart:undefined,
      dateEnd:undefined,
      guaranteeStatusOptions:[],
      etpSystemloading:false,
      enterpriseSystemOptions:[],
      params: {
        'projectName':'',
        'guaranteeId':'',
        'etpName': '',
        'guaranteeSource':'',
        'payType':undefined,
        'currentPage': 1,
        'pageSize': 10,
        'status': '',
        'start':undefined,
        'end':undefined
      },
      total: 0,
      windowHeight:0,
      // 假数据
      tableData: []
    }
  },
  created() {
     this.initListPayTypeStatus()
     this.initGuaranteeStatus()
     this.getEtpSystem()
    this.getList()
    this.updateWindowHeight()
  },
  methods: {
    initGuaranteeStatus() {
      getGuaranteeStatus().then((res) => {
        if (res.data.success) {
          this.guaranteeStatusOptions = res.data.result
        }
      })
    },
    updateWindowHeight() {
      this.tableDataHeight = window.innerHeight-250
      console.log(this.tableDataHeight)
    },
    initListPayTypeStatus(){
      listPayTypeStatus().then(res => {
         if(res.data.success){
           this.payTypeOptions=res.data.result;
         }
      })
    },
    //获取企业系统
    getEtpSystem(){
      this.etpSystemloading = true
      this.enterpriseSystemOptions=[]
      const data={
                currentPage: 1,
        pageSize: 100
      }
      listApplication(data).then((res) => {
        this.etpSystemloading=false
        if (res.data.success) {
          let appList= res.data.result.records
          appList.forEach(element => {
            let app={}
            app.code=element.appid
            app.description=element.name
            this.enterpriseSystemOptions.push(app)
          })
          console.log(this.enterpriseSystemOptions)
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    indexMethod(index){
        return (this.params.currentPage-1)*this.params.pageSize+index+1;
      },

    // 获取列表页请求
    getList() {
      if(this.tableLoading)return;
      if(this.dateStart){
        this.params.start = new Date(this.dateStart).getTime()
      }
      if(this.dateEnd){
        this.params.end = new Date(this.dateEnd).getTime()
      }
      this.tableLoading=true;
      listIncome(this.params).then(res => {
         this.tableLoading=false
         if(res.data.success){
            this.tableData = res.data.result.records
            this.total = res.data.result.total
         }else{
            this.$msgErr(res.data.message)
         }
         
      })
    },
    //按条件导出
    exportFilter() {
      if (!this.params.etpName 
           && !this.params.projectName 
           && !this.params.guaranteeId 
           && !this.params.payType
           && !this.params.status
           && !this.params.guaranteeSource
           && !this.params.start 
           && !this.params.end 
          ) {
        return this.$msgError('筛选条件不能为空')
      }
      this.tableLoading=true
      exportIncome(this.params).then(res => {
        this.tableLoading=false
        const binaryData = []
        binaryData.push(res.data)
        let url = window.URL.createObjectURL(new Blob(binaryData, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }))
            const aLink = document.createElement('a')
            aLink.style.display = 'none'
            aLink.href = url
            aLink.setAttribute('download', '收入明细导出.xls')
            document.body.appendChild(aLink)
            aLink.click()
            document.body.removeChild(aLink)
            window.URL.revokeObjectURL(url)
      })
    },
    //全部导出
    exportAll() {
      this.tableLoading=true
      const data = {}
      exportIncome(data).then(res => {
        this.tableLoading=false
        const binaryData = []
        binaryData.push(res.data)
        let url = window.URL.createObjectURL(new Blob(binaryData, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }))
        const aLink = document.createElement('a')
            aLink.style.display = 'none'
            aLink.href = url
            aLink.setAttribute('download', '收入明细全部导出.xls')
            document.body.appendChild(aLink)
            aLink.click()
            document.body.removeChild(aLink)
            window.URL.revokeObjectURL(url)
      })
    },
    // 监听pagesize
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.params.pageSize = newSize
      this.getList()
    },
    // 页码值发生切换
    handleCurrentChange(newPage) {
      // console.log(newPage)
      // console.log('将分页保存到session中')
      // const page = newPage
      // sessionStorage.setItem('page', page)
      this.params.currentPage = newPage
      this.getList()
    },
    // 清空
    deleteAddForm(formName) {
      this.params.etpName = undefined
      this.params.projectName=undefined
      this.params.start=undefined
      this.params.end=undefined
      this.dateStart=undefined
      this.dateEnd=undefined
      this.params.guaranteeId=undefined
      this.params.guaranteeSource=undefined
      this.params.status = undefined
      this.params.currentPage=1
      this.params.pageSize=10
      this.getList()
    },
    tableRowClassName({ row }) {
      if (row.isDownload === 1) {
        return 'success-row'
      } else {
        return 'warning-row'
      }
    },
    getRowKey(row) {
      // console.log('标识的id')
      // console.log(row.id)
      return row.guid
    },
    // 获取选中的id
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.ids = []
      for (let i = 0; i <= this.multipleSelection.length - 1; i++) {
        this.ids.push(this.multipleSelection[i].guid)
      }
      console.log(val)
    }
  }
}
</script>

<style scoped lang="scss">
 .el-table .warning-row {
    /* background: #f35565; */
    border: 1px solid #f35565;
    /* color:#ffffff; */
  }

  .el-table .success-row {
    background: #ffffff;
  }
  .upload{
    color:red;
  }
  ::v-deep .el-form-item {
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 10px;
}
</style>