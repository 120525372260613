import { request, post,downloadNew } from '@/plugins/request';

/**
 * 支付方式
 * @param {*} data 
 * @returns 
 */
export function listPayTypeStatus(data) {
    return post('/guarantee/v0.1/guarantee/order/top/listPayTypeStatus', data);
}
/**
 * 收入明细
 * @param {} data 
 * @returns 
 */
export function listIncome(data) {
    return post('/guarantee/v0.1/guarantee/order/top/listIncome', data);
}
/**
 * 支出明细
 * @param {*} data 
 * @returns 
 */
export function listExpenditure(data) {
    return post('/guarantee/v0.1/guarantee/order/trop/listExpenditure', data);
}

/**
 * 申请发票明细
 * @param {*} data 
 * @returns 
 */
export function listInvoice(data) {
    return post('/guarantee/v0.1/guarantee/invoice/ti/listAll', data);
}

/**
 * 发票分类
 * @param {*} data 
 * @returns 
 */
export function listInvoiceCategory(data) {
    return post('/guarantee/v0.1/guarantee/invoice/ti/getInvoiceCategory', data);
}

/**
 * 发票状态列表
 * @param {*} data 
 * @returns 
 */
export function listInvoiceStatus(data) {
    return post('/guarantee/v0.1/guarantee/invoice/ti/getInvoiceStatus', data);
}

/**
 * 开票
 * @param {*} data 
 * @returns 
 */
export function sendKp(data) {
    return post('/guarantee/v0.1/guarantee/invoice/ti/sendKp', data);
}

/**
 * 再次开票
 * @param {*} data 
 * @returns 
 */
export function sendAgainKp(data) {
    return post('/guarantee/v0.1/guarantee/invoice/ti/sendAgainKp', data);
}


/**
 * 开票明细
 * @param {*} data 
 * @returns 
 */
export function listKp(data) {
    return post('/guarantee/v0.1/guarantee/guarantee/tg/listKpAll', data);
}

/**
 * 手动开票
 * @param {*} data 
 * @returns 
 */
export function manualKp(data) {
    return post('/guarantee/v0.1/guarantee/invoice/ti/manualKp', data);
}

/**
 * 手动开票
 * @param {*} data 
 * @returns 
 */
export function exportFilter(data) {
    return downloadNew('/guarantee/v0.1/guarantee/invoice/ti/export', data);
}
/**
 * 手动开票
 * @param {*} data 
 * @returns 
 */
export function exportAll(data) {
    return downloadNew('/guarantee/v0.1/guarantee/invoice/ti/export', data);
}
/**
 * 中担保流水
 * @param {} data 
 * @returns 
 */
export function listZdb(data) {
    return post('/guarantee/v0.1/guarantee/order/to/listZdb', data);
}
/**
 * 中担保流水导出
 * @param {} data 
 * @returns 
 */
export function downloadZdb(data) {
    return downloadNew('/guarantee/v0.1/guarantee/order/to/downloadZdb', data);
}

/**
 * 导出收入明细
 * @param {*} data 
 * @returns 
 */
export function exportIncome(data) {
    return downloadNew('/guarantee/v0.1/guarantee/order/top/exportIncome', data);
}

/**
 * 导出支出明细
 * @param {*} data 
 * @returns 
 */
export function exportExpenditure(data) {
    return downloadNew('/guarantee/v0.1/guarantee/order/trop/exportExpenditure', data);
}
/**
 * 导出发票申请明细
 * @param {*} data 
 * @returns 
 */
export function exportInvoiceList(data) {
    return downloadNew('/guarantee/v0.1/guarantee/invoice/ti/exportInvoiceList', data);
}
