<template>
  <div>
    <el-form
      :model="formModel"
      ref="ruleForm3"
      class="demo-ruleForm"
      v-loading="contentLoading"
      :disabled="view"
    >
      <div
        style="
          padding-bottom: 10px;
          border-bottom: 1px solid #ccc;
          margin-bottom: 10px;
        "
      >
        <div style="font-size: 18px; text-align: center; padding-bottom: 20px">
          支付金额：{{ formModel.guaranteePayMoney }}元
        </div>
        <p>收款账户：北京银保惠通科技有限公司</p>
        <p>账号：110943409410902</p>
        <p>开户行：招商银行股份有限公司北京西二旗支行</p>
      </div>
      <el-form-item
        label="请上传转账付款凭证截图"
        prop="fileId"
        :rules="[{ required: true, message: '请上传', trigger: 'blur' }]"
      >
        <upLoadImg
          v-model="formModel.fileId"
          limit="1"
          @syncFileIds="fileIdBtn"
        >
        </upLoadImg>
      </el-form-item>
      <div style="color: #f00">请确认一下信息方便我们联系到您：</div>
      <el-form-item
        label="经办人姓名"
        prop="operator"
        :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
      >
        <el-input
          size="medium"
          v-model="formModel.operator"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="经办人手机号"
        prop="operatorPhone"
        :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
      >
        <el-input
          maxlength="11"
          size="medium"
          v-model="formModel.operatorPhone"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="保函邮寄地址"
        prop="guaranteePostAddr"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
      >
        <addressPage :etpRegAddress="etpRA" @addr-sent="initAddr"></addressPage>
      </el-form-item>
    
    <el-button
      style="width: 100%"
      size="medium"
      type="primary"
      @click="etpBtn('ruleForm3')"
      >确定</el-button
    >
    </el-form>
  </div>
</template>

<script>
import {
  getOrderTopPay,
  getDetailZtb,
  getPayOrderApi
} from '@/api/system/applyFor'
import upLoadImg from '@/components/uploadImg/index.vue'
import addressPage from '@/components/MainData/mainDataAddr.vue'
export default {
  components: {
    upLoadImg,
    addressPage
  },
  props: {
    guaranteeId: {
      type: String
    },
    guaranteePayMoney: {
      type: Number
    },
    view:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      contentLoading: false,
      formModel: {
        guaranteeId: '', // 保函id
        payType: '02',
        fileId: '', // 付款凭证
        operator: '', // 经办人姓名
        guaranteePayMoney:undefined,
        operatorPhone: '',
        guaranteePostProvince: '',
        guaranteePostCity: '',
        guaranteePostArea: '',
        guaranteePostAddr: '',
        guaranteePostStreet: ''
      },
      etpRA: {
        province: '',
        city: '',
        area: '',
        addr: '',
        street: ''
      }
    }
  },
  watch: {
    guaranteeId(v) {
      this.formModel.guaranteeId = v
    }
  },
  mounted() {
    this.formModel.guaranteeId = this.guaranteeId
    this.getDetailData()
    this.getPayOrderApiData()
  },
  methods: {
    getPayOrderApiData() {
      // 付款凭证回显
      this.contentLoading=true
      getPayOrderApi({
        guaranteeId: this.formModel.guaranteeId
      }).then((res) => {
        this.contentLoading=false
        if (res.data.success) {
          this.formModel.fileId = res.data.result.fileId
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    getDetailData() {
      // 获取详情
      this.contentLoading=true
      getDetailZtb({
        guaranteeId: this.formModel.guaranteeId
      }).then((res) => {
        if (res.data.success) {
          this.formModel.operator = res.data.result.operator
          this.formModel.operatorPhone = res.data.result.operatorPhone
          this.formModel.guaranteePayMoney=res.data.result.guaranteePayMoney
          this.etpRA = {
            province: res.data.result.guaranteePostProvince,
            city: res.data.result.guaranteePostCity,
            area: res.data.result.guaranteePostArea,
            addr: res.data.result.guaranteePostAddr,
            street: res.data.result.guaranteePostStreet
          }
        } else {
          this.$message.error(res.data.message)
        }
        this.contentLoading = false
      })
    },
    initAddr(originAddr) {
      // 邮寄地址
      this.formModel.guaranteePostProvince = originAddr.province
      this.formModel.guaranteePostCity = originAddr.city
      this.formModel.guaranteePostArea = originAddr.area
      this.formModel.guaranteePostAddr = originAddr.addr
      this.formModel.guaranteePostStreet = originAddr.street
    },
    fileIdBtn(v) {
      this.formModel.fileId = v
    },
    etpBtn(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          getOrderTopPay(this.formModel).then((res) => {
            if (res.data.success) {
              this.$message.success(res.data.message)
              this.$emit('childEditBtn')
            } else {
              this.$message.error(res.data.message)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep .el-form-item__label {
  float: none;
}
</style>
