<template>
  <div class="app-container">
    <el-form ref="params" :model="params" :inline="true">
      <el-form-item>
        <el-input v-model="params.etpName" class="input" size="mini" style="width: 150px" placeholder="投标企业" clearable
          @clear="getList" />
      </el-form-item>
      <el-form-item>
        <el-input v-model="params.projectName" class="input" size="mini" style="width: 150px" placeholder="项目名称"
          clearable @clear="getList" />
      </el-form-item>
      <el-form-item>
        <el-select v-model="params.isKp" clearable size="mini" style="width: 150px" placeholder="是否开票">
          <el-option v-for="item in invoiceOptions" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="params.guaranteeCategory" clearable size="mini" style="width: 150px" placeholder="保函分类"
          @clear="getList">
          <el-option v-for="item in guaranteeCategoryOptions" :key="item.code" :label="item.description"
            :value="item.code" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker v-model="dateStart" value-format="yyyy-MM-dd HH:mm:ss" size="mini" type="datetime"
          placeholder="申请时间开始" />
      </el-form-item>
      <el-form-item>
        <el-date-picker v-model="dateEnd" value-format="yyyy-MM-dd HH:mm:ss" size="mini" type="datetime"
          placeholder="申请时间结束" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">查询</el-button>
        <el-button size="mini" type="primary" icon="el-icon-refresh-left" @click="deleteAddForm">重置</el-button>
        <el-button size="mini" type="primary" icon="el-icon-download" @click="exportFilter">按条件导出</el-button>
        <el-button size="mini" type="primary" icon="el-icon-download" @click="exportAll">全部导出</el-button>
      </el-form-item>
    </el-form>
    <br />
    <el-table ref="multipleTable" size="mini" fit :data="tableData" border v-loading="tableLoading"
    :height="tableDataHeight"
      :row-class-name="tableRowClassName" :row-key="getRowKey" @selection-change="handleSelectionChange">
      <!-- <el-table-column type="selection" :reserve-selection="true" width="55" align="center" fixed="" /> -->
      <el-table-column type="index" :index="indexMethod" label="序号" width="50" align="center" fixed="" />
      <el-table-column prop="guaranteeId" label="唯一标识" />
      <el-table-column prop="etpName" label="企业名称" />
      <el-table-column prop="projectName" label="项目名称" />
      <el-table-column prop="projectCode" label="项目编号" />
      <el-table-column prop="timeIssue" label="保函上传时间" align="right">
        <template slot-scope="scope">{{
      $parseTime(scope.row.returnGuaranteeTime)
    }}</template>
      </el-table-column>
      <el-table-column prop="guaranteeMoney" label="担保金额(元)" align="right" />
      <el-table-column prop="guaranteePayMoney" label="支付金额（元）" align="right" />
      <el-table-column prop="status" label="开票状态" align="center" fixed="">
        <template slot-scope="scope">
          {{
        scope.row.status === '06'
          ? '已开票'
          : scope.row.status === '03'
            ? '未开票'
            : ''
      }}
        </template>
      </el-table-column>
      <el-table-column prop="invoiceCategoryName" label="发票类型" align="center" />
      <el-table-column prop="invoiceNo" label="发票号码" />
      <el-table-column prop="invoiceCode" label="发票代码" />
      <el-table-column prop="invoiceOrderNumber" label="商户订单号" align="center" />
      <el-table-column prop="invoiceKpTime" label="开票时间" align="right">
        <template slot-scope="scope">{{
        $parseTime(scope.row.invoiceKpTime)
      }}</template>

      </el-table-column>
      <el-table-column prop="created" label="申请时间" align="right">
        <template slot-scope="scope">{{
        $parseTime(scope.row.invoiceCreated)
      }}</template>
      </el-table-column>
      <el-table-column prop="guaranteeCategoryName" label="保函类别" align="center" />
    </el-table>
    <!-- 分页 -->
    <pagination v-show="total > 0" :total="total" :page.sync="params.currentPage" :limit.sync="params.pageSize"
      @pagination="getList" />
  </div>
</template>

<script>
import { listKp, exportFilter, exportAll } from '@/api/guarantee/finance'
import { getGuaranteeCategory } from '@/api/guarantee/guarantee'
export default {
  name: 'RefundList',
  data() {
    return {
      jbVisible: false,
      fileName: '压缩包',
      jbOptions: [],
      payTypeOptions: [],
      guaranteeCategoryOptions: [], //保函分类
      multipleSelection: [],
      invoiceOptions: [
        {
          value: '03',
          label: '未开票'
        },
        {
          value: '06',
          label: '已开票'
        }
      ],
      ids: [],
      tableLoading: false,
      id: '',
      name: '',
      ziptype: '',
      dateStart: undefined,
      dateEnd: undefined,
      params: {
        projectName: undefined,
        guaranteeId: undefined,
        etpName: undefined,
        isKp: undefined,
        currentPage: 1,
        pageSize: 10,
        guaranteeCategory: undefined,
        start: undefined,
        end: undefined
      },
      total: 0,
      tableDataHeight: 0,
      // 假数据
      tableData: []
    }
  },
  created() {
    this.initGuaranteeCategory()
    this.getList()
    this.updateWindowHeight()
  },
  methods: {
    updateWindowHeight() {
      this.tableDataHeight = window.innerHeight-300
      console.log(this.tableDataHeight)
    },
    initGuaranteeCategory() {
      getGuaranteeCategory().then((res) => {
        if (res.data.success) {
          this.guaranteeCategoryOptions = res.data.result
        }
      })
    },
    indexMethod(index) {
      return (this.params.currentPage - 1) * this.params.pageSize + index + 1
    },
    //按条件导出
    exportFilter() {
      if (!this.params.etpName && !this.params.projectName && !this.params.start && !this.params.end && !this.params.dateStart && !this.params.dateEnd && !this.params.guaranteeId && !this.params.isKp) {
        return this.$msgError('筛选条件不能为空')
      }
      exportAll(this.params).then(res => {
        const binaryData = []
        binaryData.push(res.data)
        let url = window.URL.createObjectURL(new Blob(binaryData, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }))
            const aLink = document.createElement('a')
            aLink.style.display = 'none'
            aLink.href = url
            aLink.setAttribute('download', '开票明细导出.xls')
            document.body.appendChild(aLink)
            aLink.click()
            document.body.removeChild(aLink)
            window.URL.revokeObjectURL(url)
      })
    },
    //全部导出
    exportAll() {
      const data = {}
      exportAll(data).then(res => {
        const binaryData = []
        binaryData.push(res.data)
        let url = window.URL.createObjectURL(new Blob(binaryData, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }))
        const aLink = document.createElement('a')
            aLink.style.display = 'none'
            aLink.href = url
            aLink.setAttribute('download', '开票明细全部导出.xls')
            document.body.appendChild(aLink)
            aLink.click()
            document.body.removeChild(aLink)
            window.URL.revokeObjectURL(url)
      })
    },
    // 获取列表页请求
    getList() {
      if (this.tableLoading) return
      if (this.dateStart) {
        this.params.start = new Date(this.dateStart).getTime()
      }
      if (this.dateEnd) {
        this.params.end = new Date(this.dateEnd).getTime()
      }
      this.tableLoading = true
      listKp(this.params).then((res) => {
        this.tableLoading = false
        if (res.data.success) {
          this.tableData = res.data.result.records
          this.total = res.data.result.total
        } else {
          this.$msgErr(res.data.message)
        }
      })
    },
    // 监听pagesize
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.params.pageSize = newSize
      this.getList()
    },
    // 页码值发生切换
    handleCurrentChange(newPage) {
      // console.log(newPage)
      // console.log('将分页保存到session中')
      // const page = newPage
      // sessionStorage.setItem('page', page)
      this.params.currentPage = newPage
      this.getList()
    },
    // 清空
    deleteAddForm(formName) {
      this.params.etpName = ''
      this.params.projectName = ''
      this.params.start = undefined
      this.params.end = undefined
      this.dateStart = undefined
      this.dateEnd = undefined
      this.params.guaranteeId = ''
      this.params.isKp = undefined
      this.params.currentPage = 1
      this.params.pageSize = 10
      this.getList()
    },
    tableRowClassName({ row }) {
      if (row.isDownload === 1) {
        return 'success-row'
      } else {
        return 'warning-row'
      }
    },
    getRowKey(row) {
      // console.log('标识的id')
      // console.log(row.id)
      return row.guid
    },
    // 获取选中的id
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.ids = []
      for (let i = 0; i <= this.multipleSelection.length - 1; i++) {
        this.ids.push(this.multipleSelection[i].guid)
      }
      console.log(val)
    }
  }
}
</script>

<style>
.el-table .warning-row {
  /* background: #f35565; */
  border: 1px solid #f35565;
  /* color:#ffffff; */
}

.el-table .success-row {
  background: #ffffff;
}

.upload {
  color: red;
}
</style>