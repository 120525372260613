import {request,post} from '@/plugins/request';

// 查询分页列表
export function list(data) {
  return post('/guarantee/v0.1/guarantee/agent/ta/listU',data);
}

// 查询详细
export function detail(data) {
  return post('/guarantee/v0.1/guarantee/agent/ta/detailU',data);
}

// 新增
export function create(data) {
  return post('/guarantee/v0.1/guarantee/agent/ta/createU',data);
}

// 修改
export function update(data) {
  return post('/guarantee/v0.1/guarantee/agent/ta/updateU',data);
}

// 删除
export function del(data) {
  return post('/guarantee/v0.1/guarantee/agent/ta/deleteU',data);
}


