<template>
  <div>
    <div class="drawerTitle">
      <div class="kh">
        <span
          ><i class="el-icon-potato-strips"></i>
          {{ user.loginName }}</span
        >
      </div>
      <div class="sub">
        <span>姓名：{{user.name?user.name:''}}</span>
        <span>登录名： {{user.loginName?user.loginName:''}}</span>
        <span>电话：  {{user.phone?user.phone:''}}</span>
        <span>邮箱：  {{user.email?user.email:''}}</span>
        <span>性别：   {{user.sex===0?'男':'女'}}</span>
        <span>状态：    {{user.statusName?user.statusName:''}}</span>
      </div>
    </div>
    <el-divider><i class="el-icon-arrow-down"></i></el-divider>
    <div class="drawerContent">
      <div
        class="left"
        :style="{ height: windowHeight + 'px', 'overflow-y': 'auto' }"
      >
        <el-tabs type="border-card">
          <el-tab-pane>
            <span slot="label"><i class="el-icon-date"></i> 企业信息</span>
            <etpViewVue v-model="user.guid"></etpViewVue>
          </el-tab-pane>
           <!-- <el-tab-pane label="保函信息">
            <guaranteeView v-model="user.guid"></guaranteeView>
          </el-tab-pane> -->
         <!-- <el-tab-pane label="相关文件">
            <lvGuaranteeViewFile v-model="lvGuaranteeInfo"></lvGuaranteeViewFile>
          </el-tab-pane>
          <el-tab-pane label="付款记录">
            <lvGuaranteeViewPay v-model="lvGuaranteeInfo"></lvGuaranteeViewPay>
          </el-tab-pane>
          <el-tab-pane label="邮寄记录">
            <lvGuaranteeViewPost v-model="lvGuaranteeInfo"></lvGuaranteeViewPost>
          </el-tab-pane>
          <el-tab-pane label="开票信息">
            <lvGuaranteeViewInvoice v-model="lvGuaranteeInfo"></lvGuaranteeViewInvoice>
          </el-tab-pane> -->
        </el-tabs>
      </div>
      <!-- <div
        class="right"
        :style="{ height: windowHeight + 'px', overflow: 'hidden' }"
      >
        <lvGuaranteeViewFl v-model="lvGuarantee.guaranteeUnique"></lvGuaranteeViewFl>
      </div> -->
    </div>
  </div>
</template>

<script>
import etpViewVue from './etpView.vue'
import guaranteeView from './etpGuaranteeView.vue'
export default {
    name:'lvGuaranteeView',
    components:{
       etpViewVue,guaranteeView
    },
    data() {
    return {
      windowHeight: 0,
      user: this.value,
      myValue: this.value
    }
  },
  props: {
    value: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  created() {
    this.initWindowHeight()
  },
  mounted() {

  },
  methods: {
    initWindowHeight() {
      let pageHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
      this.windowHeight = pageHeight - 140
      console.log(this.windowHeight)
    }
  },
  watch: {
    value(newValue) {
      this.user = newValue;
    }
  }
}
</script>
<style scoped lang="scss">
.drawerTitle {
  margin: 15px 0 0 10px;

  line-height: 30px;

  .kh {
    font-weight: bold;
    color: #6a6969;
  }

  .sub {
    span {
      margin: 0 20px 0 0;
      font-size: 14px;
      color: #72767b;
    }
  }
}
.drawerContent {
  display: flex;

  .left {
    width: 100%;
    margin: 0 0 0 10px;
  }

  .right {
    width: 28%;
    margin: 0 0 0 10px;
    border: 1px solid #dcdfe6;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    padding: 8px;
    .header {
      padding: 0 0 0 10px;

      span {
        line-height: 40px;
        list-style: none;
        font-size: 14px;
        font-weight: 500;
        color: #303133;
      }
    }
  }
}
</style>
