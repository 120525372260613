import { post } from '@/plugins/request'

// 获取验证码
export function uasSendSMSCode(data) {
  return post('/guarantee/v0.1/guarantee/user/tu/sendSmsCode', data)
}
// 查询企业是否存在
export function getValidLoginName(data) {
  return post('/guarantee-service/v0.1/guarantee/user/tu/validLoginName', data)
}
// 获取企业信息
export function getDetailC(data) {
  return post('/guarantee-service/v0.1/guarantee/user/tc/detailCByUserId', data)
}
// 申请保函
export function getApplyZtb(data) {
  return post(
    '/guarantee-service/v0.1/guarantee/guarantee/tg/applyZtb',
    data
  )
}
// 保函详情
export function getDetailZtb(data) {
  return post(
    '/guarantee-service/v0.1/guarantee/guarantee/tg/detailZtb',
    data
  )
}
// 更新保函
export function getUpdateZtb(data) {
  return post(
    '/guarantee-service/v0.1/guarantee/guarantee/tg/updateZtb',
    data
  )
}
// 保函支付
export function getZtbPay(data) {
  return post('/guarantee-service/v0.1/guarantee/order/top/pay', data)
}
// 保函申请状态
export function getListGuaranteeStatus(data) {
  return post(
    '/guarantee-service/v0.1/guarantee/guarantee/tg/listGuaranteeStatus',
    data
  )
}
// 下载文件
export function downloadFile(data) {
  return download('/store/v0.1/store/store/attach/download', data)
}
// 删除上传文件
export function delUpload(data) {
  return post('/store/v0.1/store/store/attach/delete', data)
}

// 预览上传文件
export function viewUpload(guid) {
  return service({
    url: '/store/v0.1/attachment/viewImgBase64?guid=' + guid,
    method: 'post'
  })
}
// 上传付款凭证接口
export function getOrderTopPay(data) {
  return post('/guarantee-service/v0.1/guarantee/order/top/pay', data)
}
// 上传付款凭证接口
export function getaddSignFiles(data) {
  return post(
    '/guarantee-service/v0.1/guarantee/guarantee/tg/addSignFiles',
    data
  )
}
// 上传的付款凭证回显
export function getPayOrderApi(data) {
  return post('/guarantee-service/v0.1/guarantee/order/to/getPayOrder', data)
}

export function detailC(data) {
  return post('/guarantee/v0.1/guarantee/user/tc/detailC', data)
}
export function confirmReceiveMoney(data) {
  return post(
    '/guarantee-service/v0.1/guarantee/order/top/confirmReceiveMoney',
    data
  )
}
