<template>
  <div class="app-container">
    <el-form
      ref="params"
      :model="params"
      :inline="true"
    >
      <el-form-item>
        <el-input
          v-model="params.etpName"
          class="input"
          size="mini"
          style="width:150px"
          placeholder="投标企业"
          clearable
          @clear="getList"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="params.projectName"
          class="input"
          size="mini"
          style="width:150px"
          placeholder="项目名称"
          clearable
          @clear="getList"
        />
      </el-form-item>
      <!-- <el-form-item>
        <el-input
          v-model="name"
          :disabled="true"
          class="input"
          size="mini"
          style="width:150px"
          placeholder="所属项目"
        />
      </el-form-item> -->
      <el-form-item>
        <el-input
          v-model="params.guaranteeId"
          class="input"
          size="mini"
          style="width:150px"
          placeholder="保函唯一标识"
          clearable
          @clear="getList"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="params.status"
          clearable
          size="mini"
          style="width:150px;"
          placeholder="退款状态"
          @clear="getList"
        >
          <el-option
            v-for="item in reFundOrderOptions"
            :key="item.code"
            :label="item.description"
            :value="item.code"
          />
        </el-select>
        </el-form-item>
        <el-form-item>
        <el-select
          v-model="params.guaranteeSource"
          clearable
          size="mini"
          style="width: 150px"
          placeholder="保函来源"
          @clear="getList"
        >
          <el-option
            v-for="item in enterpriseSystemOptions"
            :key="item.code"
            :label="item.description"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
        <el-form-item>
        <el-date-picker
          v-model="dateStart"
          value-format="yyyy-MM-dd HH:mm:ss"
          size="mini"
          type="datetime"
          placeholder="申请时间开始"
        />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="dateEnd"
          value-format="yyyy-MM-dd HH:mm:ss"
          size="mini"
          type="datetime"
          placeholder="申请时间结束"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-search"
          @click="getList"
        >查询</el-button>
        <el-button size="mini" type="primary" icon="el-icon-refresh-left" @click="deleteAddForm">重置</el-button>
         <el-button size="mini" type="primary" icon="el-icon-download" @click="exportFilter">按条件导出</el-button>
        <el-button size="mini" type="primary" icon="el-icon-download" @click="exportAll">全部导出</el-button>
      </el-form-item>
    </el-form>
      <br>
    <el-table
      ref="multipleTable"
      size="mini"
      fit
      :data="tableData"
      border
      v-loading="tableLoading"
      :height="tableDataHeight"
      :row-class-name="tableRowClassName"
      :row-key="getRowKey"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" :reserve-selection="true" width="55" align="center" fixed="" />
      <el-table-column type="index" label="序号" width="50" :index="indexMethod" align="center" fixed="" />
      <el-table-column label="操作" width="150" align="center" fixed="">
        <template slot-scope="scope">
          <el-button v-if="menuPriPath.indexOf('confirm-refund') >= 0" :disabled="scope.row.status!='00'" size="mini" type="primary" @click="refundSubmit(scope.row.guid)">确认退款</el-button>
          <el-button style="margin:8px 0 0 0px" v-if="scope.row.status==='03' && menuPriPath.indexOf('again-refund') >= 0" size="mini" type="primary" @click="refundAgainSubmit(scope.row.guid)">再次退款</el-button>
           <el-button style="margin:8px 0 0 0px" v-if="scope.row.refundType==='01' && scope.row.status==='01' && menuPriPath.indexOf('manual-refund') >= 0" size="mini" type="primary" @click="manualRefundSubmit(scope.row.guid)">线下退款完成</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="statusName" label="状态" align="center" />
      <el-table-column prop="orderId" label="原始订单号" align="center"/>
      <el-table-column prop="projectCode" label="项目编码" align="center"/>
      <el-table-column prop="projectName" label="项目名称" align="center"/>
      <el-table-column prop="etpName" label="投标企业" align="center" />
      <el-table-column prop="operator" label="经办人" align="center" />
      <el-table-column prop="operatorPhone" label="经办人联系方式" align="center" />
      <el-table-column prop="rejectReason" label="拒保原因" align="center" />
      <el-table-column prop="rejectTime" label="拒保时间" align="center">
        <template slot-scope="scope">{{$parseTime(scope.row.rejectTime)}}</template>
      </el-table-column>
      <el-table-column prop="refundMoney" label="退款金额(元)" align="right" />
      <el-table-column prop="guaranteeSourceName" label="保函来源" align="center" />
      <el-table-column prop="guaranteeId" label="保函唯一标识" align="center" />
      <!-- <el-table-column prop="respMsg" label="备注" align="right">
        <template slot-scope="scope">
           <span v-if="scope.row.states===3">{{scope.row.respMsg}}</span>
        </template>
      </el-table-column> -->
    </el-table>
    <!-- 分页 -->
      <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="params.currentPage"
          :limit.sync="params.pageSize"
          @pagination="getList"
        />
  </div>
</template>

<script>
import {
  listApplication
} from '@/api/system/application'
import { getMenuPriByMenuId } from '@/api/menu/index'
import { list,listRefundOrderStatus,confirmRefund ,manualRefund,exportRefundList} from '@/api/guarantee/refund'
export default {
  name: 'RefundList',
  data() {
    return {
      menuPriPath:[],//菜单角色权限
      jbVisible:false,
      fileName: '压缩包',
      jbOptions:[],
      tableDataHeight:0,
      reFundOrderOptions: [],
      multipleSelection: [],
      enterpriseSystemOptions:[],
      dateStart:undefined,
      dateEnd:undefined,
      ids: [],
      tableLoading:false,
      id: '',
      name: '',
      ziptype: '',
      params: {
        'projectName':'',
        'guaranteeId':'',
        'guaranteeSource':'',
        'start':undefined,
        'end':undefined,
        'etpName': '',
        'currentPage': 1,
        'pageSize': 10,
        'status': ''
      },
      total: 0,
      windowHeight:0,
      // 假数据
      tableData: []
    }
  },
  created() {
    this.getEtpSystem()
      this.getRolePriList()
     this.initRefundOrderStatus()
    this.getList()
    this.updateWindowHeight()
  },
  methods: {
    updateWindowHeight() {
      this.tableDataHeight = window.innerHeight-250
      console.log(this.tableDataHeight)
    },
    //获取企业系统
    getEtpSystem(){
      this.etpSystemloading = true
      this.enterpriseSystemOptions=[]
      const data={
                currentPage: 1,
        pageSize: 100
      }
      listApplication(data).then((res) => {
        this.etpSystemloading=false
        if (res.data.success) {
          let appList= res.data.result.records
          appList.forEach(element => {
            let app={}
            app.code=element.appid
            app.description=element.name
            this.enterpriseSystemOptions.push(app)
          })
          console.log(this.enterpriseSystemOptions)
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    getRolePriList() {
      var data = {
        menuGuid: this.$route.query.menuGuid
      }
      getMenuPriByMenuId(data).then((res) => {
        if (res.data.success) {
          for (var i = 0; i < res.data.result.length; i++) {
            this.menuPriPath.push(res.data.result[i].privilegeAction)
          }
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    initRefundOrderStatus(){
      listRefundOrderStatus().then(res => {
         if(res.data.success){
           this.reFundOrderOptions=res.data.result;
         }
      })
    },
    indexMethod(index){
        return (this.params.currentPage-1)*this.params.pageSize+index+1;
      },

    // 获取列表页请求
    getList() {
      if(this.tableLoading)return;
       if(this.dateStart){
        this.params.start = new Date(this.dateStart).getTime()
      }else{
        this.params.start=undefined
      }
      if(this.dateEnd){
        this.params.end = new Date(this.dateEnd).getTime()
      }else{
        this.params.end=undefined
      }
      this.tableLoading=true;
      list(this.params).then(res => {
         this.tableLoading=false
         this.tableData = res.data.result.records
        this.total = res.data.result.total
      })
    },
    //按条件导出
    exportFilter() {
      if (!this.params.etpName 
           && !this.params.projectName 
           && !this.params.guaranteeId 
           && !this.params.status
           && !this.params.guaranteeSource
           && !this.params.start 
           && !this.params.end 
          ) {
        return this.$msgError('筛选条件不能为空')
      }
      this.tableLoading=true
      exportRefundList(this.params).then(res => {
        this.tableLoading=false
        const binaryData = []
        binaryData.push(res.data)
        let url = window.URL.createObjectURL(new Blob(binaryData, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }))
            const aLink = document.createElement('a')
            aLink.style.display = 'none'
            aLink.href = url
            aLink.setAttribute('download', '退款申请导出.xls')
            document.body.appendChild(aLink)
            aLink.click()
            document.body.removeChild(aLink)
            window.URL.revokeObjectURL(url)
      })
    },
    //全部导出
    exportAll() {
      this.tableLoading=true
      const data = {}
      exportRefundList(data).then(res => {
        this.tableLoading=false
        const binaryData = []
        binaryData.push(res.data)
        let url = window.URL.createObjectURL(new Blob(binaryData, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }))
        const aLink = document.createElement('a')
            aLink.style.display = 'none'
            aLink.href = url
            aLink.setAttribute('download', '退款申请全部导出.xls')
            document.body.appendChild(aLink)
            aLink.click()
            document.body.removeChild(aLink)
            window.URL.revokeObjectURL(url)
      })
    },
    // 监听pagesize
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.params.pageSize = newSize
      this.getList()
    },
    // 页码值发生切换
    handleCurrentChange(newPage) {
      // console.log(newPage)
      // console.log('将分页保存到session中')
      // const page = newPage
      // sessionStorage.setItem('page', page)
      this.params.currentPage = newPage
      this.getList()
    },
    // 清空
    deleteAddForm(formName) {
      this.params.etpName = ''
      this.params.projectName=''
      this.params.guaranteeId=''
      this.params.status = ''
      this.params.currentPage=1
      this.params.pageSize=10
      this.params.guaranteeSource=undefined
      this.params.start=undefined
      this.params.end=undefined
      this.dateStart=undefined
      this.dateEnd=undefined
      this.getList()
    },
    tableRowClassName({ row }) {
      if (row.isDownload === 1) {
        return 'success-row'
      } else {
        return 'warning-row'
      }
    },
    getRowKey(row) {
      // console.log('标识的id')
      // console.log(row.id)
      return row.guid
    },
    // 获取选中的id
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.ids = []
      for (let i = 0; i <= this.multipleSelection.length - 1; i++) {
        this.ids.push(this.multipleSelection[i].guid)
      }
      console.log(val)
    },
    //确认退款操作
    refundSubmit(guid){
       this.$confirm('此操作将退款, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
            console.log('refund')
            const data={
              guid
            }
            confirmRefund(data).then(res => {
              console.log(res)
              if(res.data.success){
                this.$message({
                  type: 'success',
                  message: '已发起退款'
                })
                this.getList()
              }else{
                 this.$message({
                  type: 'error',
                  message: res.data.message
                })
              }
            })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消退款'
        })
      })
    },
    //手动退款操作
    manualRefundSubmit(guid){
       this.$confirm('此操作将设置订单线下退款成功, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
            console.log('refund')
            const data={
              guid
            }
            manualRefund(data).then(res => {
              console.log(res)
              if(res.data.success){
                this.$message({
                  type: 'success',
                  message: '已设置订单线下退款成功'
                })
                this.getList()
              }else{
                 this.$message({
                  type: 'error',
                  message: res.data.message
                })
              }
            })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消线下退款'
        })
      })
    },
    //再次退款操作
    refundAgainSubmit(guid){
       this.$confirm('此操作将再次退款, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
            console.log('refundAgain')
            const data={
              guid
            }
            confirmRefund(data).then(res => {
              console.log(res)
              if(res.data.success){
                this.$message({
                  type: 'success',
                  message: '已发起再次退款'
                })
                this.getList()
              }else{
                 this.$message({
                  type: 'error',
                  message: res.data.message
                })
              }
            })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消再次退款'
        })
      })
    }

  }
}
</script>

<style scoped lang="scss">
 .el-table .warning-row {
    /* background: #f35565; */
    border: 1px solid #f35565;
    /* color:#ffffff; */
  }

  .el-table .success-row {
    background: #ffffff;
  }
  .upload{
    color:red;
  }
  ::v-deep .el-form-item {
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 10px;
  }
</style>