<template>
  <div>
    <div class="customerInfoContent" v-loading="loading">
      <ul>
        <li>
          <span class="title">企业名称：</span
          ><span class="content">{{ etp.etpName }}</span>
        </li>
        <li>
          <span class="title">统一社会信用代码：</span
          ><span class="content">{{ etp.etpUscc }}</span>
        </li>
        <li>
          <span class="title">注册资本：</span
          ><span class="content">{{ etp.etpRegCapital }}</span>
        </li>
        <li>
          <span class="title">所属行业：</span
          ><span class="content">{{ etp.etpIndustryName }}</span>
        </li>
        <li>
          <span class="title">企业性质：</span
          ><span class="content">{{ etp.etpPropertyName }}</span>
        </li>
        <li>
          <span class="title">证件有效期：</span
          ><span class="content"
            >{{ $parseTime(etp.etpCertBeginDate) }} 至
            {{ $parseTime(etp.etpCertEndDate) }}</span
          >
        </li>
        <li>
          <span class="title">企业注册地址：</span
          ><span class="content">{{ etp.etpFullAddr }}</span>
        </li>
        <li>
          <span class="title">营业执照：</span
          ><span class="content">
            <upLoadImg
              v-model="etp.etpIdcard"
              :limit="upload.limit"
              :viewImage="upload.viewImage"
            >
            </upLoadImg
          ></span>
        </li>
      </ul>
    </div>
  </div>
</template>
  
  <script>
import { detailEtp } from '@/api/user/company'
import upLoadImg from '@/components/uploadImg/index.vue'
export default {
  name: 'etpInfo',
  data() {
    return {
      loading: false,
      etp: {},
      upload: {
        limit: 1,
        viewImage: true
      },
      userId: this.value
    }
  },
  components: {
    upLoadImg
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.initEtpInfo()
  },
  methods: {
    initEtpInfo() {
      if (!this.userId) return
      this.loading = true
      const data = {
        userId: this.userId
      }
      detailEtp(data).then((res) => {
        this.loading = false
        if (res.data.success) {
          this.etp = res.data.result
        } else {
          this.$msgError(res.data.message)
        }
      })
    }
  },
  watch: {
    value(newValue) {
      this.userId = newValue
      this.initEtpInfo()
    }
  }
}
</script>
  
  <style lang="scss" scoped>
.customerInfoContent {
  display: grid;
  ul,
  li {
    list-style-type: none;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
  }
  li {
    line-height: 28px;
    margin-bottom: 1px;
    .title {
      width: 20%;
      text-align: right;
      vertical-align: top;
    }
    .content {
      width: 80%;
      background-color: #f5f5f5;
      padding: 0 5px 0 5px;
    }
  }
  span {
    font-size: 14px;
    color: #72767b;
    display: inline-block;
  }
}
</style>
  