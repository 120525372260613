<template>
  <div class="complete">
    <el-table
      ref="multipleTable"
      size="mini"
      fit
      :data="tableData"
      style="width: 100%; margin-top: 10px;"
      border
      v-loading="loading"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column label="文件" align="center">
        <template slot-scope="scope">
          <span class="filename">{{ scope.row.fileName }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top:20px;">
      <el-button size="mini" type="primary" @click="predowan">预览</el-button>
      <el-button size="mini" type="primary" @click="download">下载</el-button>
      <!-- <el-button size="mini" type="primary" @click="goBack">取消</el-button> -->
    </div>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="90%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <el-dialog :visible.sync="dialogExcelVisible" width="80%" append-to-body>
      <el-table :data="tableDataPreExcel" border highlight-current-row style="width: 100%;margin-top:20px;">
        <el-table-column v-for="item of tableHeaderPreExcel" :key="item" :prop="item" :label="item" />
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import XLSX from 'xlsx'
// 增 删 改 查 Add, delete, modify, check
import { businessDetails,getGuaranteeFile,downloadGuaranteeFile} from '@/api/guarantee/guarantee'
// import preview from './preview/index'
export default {
  name: 'List',
  components: { 
    // preview
     },
  data() {
    return {
      dialogExcelVisible:false,
      dialogVisible:false,
      dialogImageUrl:'',
      tableDataPreExcel: [],
      tableHeaderPreExcel: [],
      proId: '',
      multipleSelection: [],
      ids: [],
      urlList: [],
      guaranteeId: this.value,
      loading:false,
      params: {
        'currentPage': 1,
        'pageSize': 10
      },
      ziptype: '',
      total: 0,
      // 假数据
      tableData: []
    }
  },
  props:{
        value: {
            type: String,
            default: ''
        },
        fileName:{
          type: String,
          default:'压缩包'
        }
  },
  created() {
    // 挂载，让页面更新就获取数据
     this.getList()
  },
  methods: {
    getFarther() {
      console.log('父组件过啦的数据')
      console.log(this.$route.query)
      this.proId = parseInt(this.$route.query.id)
    },
    // 获取列表页请求
    getList() {
      if(!this.guaranteeId)return;
      const data = {
        guaranteeId: this.guaranteeId
      }
      this.loading=true
      businessDetails(data).then(res => {
        this.loading=false
        if(res.data.success){
          this.tableData = res.data.result
        }else{
          this.$msgError(res.data.message)
        }
        
      })
    },
    // 获取选中的id
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.ids = []
      this.urlList = []
      for (let i = 0; i <= this.multipleSelection.length - 1; i++) {
        this.ids.push(this.multipleSelection[i].id)
        this.urlList.push(this.multipleSelection[i])
      }
    },
    predowan() {
      if (this.ids.length > 1) {
        this.$message({
          message: '请选择一条数据进行预览',
          type: 'warning'
        })
      } else if (this.ids.length === 0) {
        this.$message({
          message: '请选择一条数据进行预览',
          type: 'warning'
        })
      } else {
        console.log('预览')
        this.tableDataPreExcel=[]
        this.tableHeaderPreExcel=[]
        console.log(this.urlList[0].fileCategory)
        this.ziptype = this.urlList[0].fileName.split('.').pop()
        const params = {
          guaranteeId: this.guaranteeId,
          fileCategory: this.urlList[0].fileCategory
        }
        this.loading=true
        getGuaranteeFile(params).then(res => {
          this.loading=false
          console.log('111111')
          console.log(res)
          let url='';
          const binaryData = []
          binaryData.push(res.data)
          console.log(this.ziptype)
          if (this.ziptype === 'pdf') {
            console.log('pdf')
              url = window.URL.createObjectURL(new Blob(binaryData, {
                type: 'application/pdf'
              }))
               window.open(url, 'newWindow');
          } else if (this.ziptype === 'png') {
             console.log('png')
              url = window.URL.createObjectURL(new Blob(binaryData, {
                type: 'image/png'
              }))
              this.dialogImageUrl=url
              this.dialogVisible=true
          } else if (this.ziptype === 'jpg'||this.ziptype === 'jpeg') {
            console.log(this.ziptype)
              url = window.URL.createObjectURL(new Blob(binaryData))
              this.dialogImageUrl=url
              this.dialogVisible=true
          } else if (this.ziptype === 'xlsx') {
             console.log(this.ziptype)
              // url = window.URL.createObjectURL(new Blob(binaryData, {
              //   type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              // }))
              //  window.open(url, 'newWindow');
               return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                  const data = e.target.result
                  //  const data = new Uint8Array(e.target.result);
                  const workbook = XLSX.read(data, { type: 'array' })
                  const firstSheetName = workbook.SheetNames[0]
                  const worksheet = workbook.Sheets[firstSheetName]
                  const tableHeader = this.getHeaderRow(worksheet)
                  const tableResults = XLSX.utils.sheet_to_json(worksheet)
                  this.tableDataPreExcel = tableResults
                  console.log('tableData',this.tableDataPreExcel)
                  this.tableHeaderPreExcel = tableHeader
                  resolve()
                }
                reader.readAsArrayBuffer(res.data)
                this.dialogExcelVisible=true
              })
          } else if (this.ziptype === 'doc') {
              url = window.URL.createObjectURL(new Blob(binaryData, {
                type: 'application/msword'
              }))
               window.open(url, 'newWindow');
          } else if(this.ziptype==='docx'){
              url = window.URL.createObjectURL(new Blob(binaryData, {
                type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              }))
               window.open(url, 'newWindow');
          }else {
           return  this.$message({
              message: '文件格式错误',
              type: 'warning'
            })
          }
          
        })
      }
    },
    getHeaderRow(sheet) {
      const headers = []
      const range = XLSX.utils.decode_range(sheet['!ref'])
       console.log('=================range==================')
      console.log(range)
      let C
      const R = range.s.r
      console.log('=================R==================')
      console.log(R)
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) { /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]
        console.log('====================cell=============')
        console.log(cell)
        /* find the cell in the first row */
        let hdr = 'UNKNOWN ' + C // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)
        headers.push(hdr)
      }
      return headers
    },
    download() {
      console.log('下载')
      console.log(this.ids)
      if (this.ids.length === 0) {
        this.$message({
          message: '请选择数据进行下载',
          type: 'warning'
        })
      } else {
         let list=[]
         this.urlList.forEach(item=>{
          list.push(item.fileCategory)
         })
         const params = {
          guaranteeId: this.guaranteeId,
          fileCategoryList: list
         }
         this.loading=true
         downloadGuaranteeFile(params).then(res => {
          this.loading=false
          console.log('压缩包打印数据')
          // console.log(res)
          const content = res.data
          const blob = new Blob([content], { type: 'application/zip' })
          const fileName = this.fileName
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href) // 释放URL 对象
          document.body.removeChild(elink)
        })
      }
    },
    goBack() {
      console.log('取消')
      this.$router.go(-1)
    }
  },
  watch: {
        value(newValue) {
             this.guaranteeId = newValue
             this.getList()
        },
        fileName(newValue){
          this.fileName=newValue
        }
        
    }
}
</script>
<style scoped>
.filename{
  border-bottom: 1px solid #9E9E9E;
}
</style>
