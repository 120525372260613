import { request, post, downloadNew } from '@/plugins/request'

// 查询列表
export function list(data) {
  return post('/guarantee/v0.1/guarantee/guarantee/tg/list', data)
}

// 获取保函状态
export function getGuaranteeStatus(data) {
  return post(
    '/guarantee/v0.1/guarantee/guarantee/tg/listGuaranteeStatus',
    data
  )
}
// 获取保函分类
export function getGuaranteeCategory(data) {
  return post(
    '/guarantee/v0.1/guarantee/guarantee/tg/listGuaranteeCategory',
    data
  )
}
// 获取保函类型
export function getGuaranteeType(data) {
  return post('/guarantee/v0.1/guarantee/guarantee/tg/listGuaranteeType', data)
}
// 拒保操作
export function reject(data) {
  return post('/guarantee/v0.1/guarantee/guarantee/tg/reject', data)
}
// 审核操作
export function confirmBh(data) {
  return post('/guarantee/v0.1/guarantee/guarantee/tg/confirm', data)
}
// 保存上传保函
export function saveReturnGuarantee(data) {
  return post(
    '/guarantee/v0.1/guarantee/guarantee/tg/saveReturnGuarantee',
    data
  )
}

// 企业信息文件列表
export function businessDetails(data) {
  return post('/guarantee/v0.1/guarantee/guarantee/tg/businessDetails', data)
}
//获取文件
export function getGuaranteeFile(data) {
  return downloadNew(
    '/guarantee/v0.1/guarantee/guarantee/tg/getGuaranteeFile',
    data
  )
}
//下载文件
export function downloadGuaranteeFile(data) {
  return downloadNew(
    '/guarantee/v0.1/guarantee/guarantee/tg/downloadGuaranteeFile',
    data
  )
}
//批量下载文件
export function listDownload(data) {
  return downloadNew(
    '/guarantee/v0.1/guarantee/guarantee/tg/listDownload',
    data
  )
}
// 查看经办人
export function getOperator(data) {
  return post('/guarantee/v0.1/guarantee/guarantee/tg/getOperator', data)
}
// 审核操作
export function modifyGuaranteePayMoney(data) {
  return post('/guarantee/v0.1/guarantee/guarantee/tg/modifyGuaranteePayMoney', data)
}
//下载所有签约资料
export function downLoadAllSignedFiles(data) {
  return downloadNew(
    '/guarantee/v0.1/guarantee/guarantee/tg/getAllSignedFiles',
    data
  )
}