<template>
  <div class="app-container">
    <div style="width: 70%; margin: 0 auto">
      <el-steps :active="0">
        <el-step title="填写企业信息" description=""></el-step>
        <el-step title="填写申请信息" description=""></el-step>
        <el-step title="付款" description=""></el-step>
        <el-step title="开立保函" description=""></el-step>
      </el-steps>
    </div>
    <div v-if="isUser" class="content_box">
      <div class="input_box">
        <span>企业用户账号：</span>
        <el-input
          v-model="loginName"
          maxlength="11"
          placeholder="请输入手机号"
        ></el-input>
      </div>
      <el-button type="primary" @click="nextBtn">下一步</el-button>
    </div>
    <div v-else class="content_box">
      <div class="title">此账号未注册，请先注册企业账号</div>
      <div class="input_box">
        <span>企业用户账号：</span>
        <el-input
          v-model="loginName"
          maxlength="11"
          placeholder="请输入手机号"
        ></el-input>
      </div>
      <div class="input_box">
        <span>验证码：</span>
        <el-input size="medium" v-model="code" placeholder="请输入验证码">
          <el-tooltip slot="suffix" content="点击发送验证码">
            <send-sms-code :telephone="loginName" :smsType="smsType" />
          </el-tooltip>
        </el-input>
      </div>
      <el-button type="primary" @click="registerBtn">下一步</el-button>
    </div>
  </div>
</template>
<script>
import SendSmsCode from '@/components/SendSmsCode/index.vue'
import { getValidLoginName } from '@/api/system/applyFor'
// getValidLoginName
export default {
  components: {
    SendSmsCode
  },
  data() {
    return {
      smsType: 'reg',
      loginName: '',
      code: '',
      loading: false,
      isUser: true,
      guaranteeCategory: ''
    }
  },
  mounted() {
    this.guaranteeCategory = this.$route.query.guaranteeCategory
  },
  methods: {
    nextBtn() {
      if (!this.loginName) {
        this.$msgError('请输入企业账号')
        return
      }
      this.loading = true
      getValidLoginName({ loginName: this.loginName, loginType: 1 }).then(
        (res) => {
          this.loading = false
          if (res.data.success) {
            if (!res.data.result.isUser) {
              this.isUser = res.data.result.isUser
              return
            }
            if (this.guaranteeCategory === '01') {
              this.$router.push({
                path: '/system/applyFor/begin',
                query: {
                  userId: res.data.result.userId
                }
              })
            } else {
              this.$router.push({
                path: '/system/applyForMore/begin',
                query: {
                  userId: res.data.result.userId,
                  guaranteeCategory: this.guaranteeCategory
                }
              })
            }
          } else {
            this.$msgError(res.data.message)
          }
        }
      )
    },
    registerBtn() {
      this.loading = true
      getValidLoginName({
        loginName: this.loginName,
        loginType: 1,
        code: this.code
      }).then((res) => {
        this.loading = false
        if (res.data.success) {
          if (this.guaranteeCategory === '01') {
            this.$router.push({
              path: '/system/applyFor/begin',
              query: {
                userId: res.data.result.userId
              }
            })
          } else {
            this.$router.push({
              path: '/system/applyForMore/begin',
              query: {
                userId: res.data.result.userId,
                guaranteeCategory: this.guaranteeCategory
              }
            })
          }
        } else {
          this.$msgError(res.data.message)
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.content_box {
  width: 500px;
  margin: 0 auto;
  padding: 100px 0;
  text-align: center;

  .title {
    font-size: 18px;
    padding-bottom: 20px;
  }

  .input_box {
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    font-size: 18px;

    span {
      width: 170px;
    }
  }

  button {
    width: 200px;
    margin: 50px auto;
  }
}

::v-deep .el-input__suffix {
  line-height: 36px;
}
</style>
