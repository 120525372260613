<template>
  <div :class="[viewImage ? 'component-upload-image noneUpload' : 'component-upload-image']">
    <el-upload v-if="pageType && pageType == 'ocr'" class="avatar-uploader" list-type="picture-card" :limit='1'
      action="#" :accept="accept" :file-list="fileList" :on-exceed="handleExceed" :http-request="upload"
      :on-remove="handleRemove" :on-preview="handlePreview" v-loading="loading">
      <el-button type="primary" size="small" v-if="!viewImage">点击上传</el-button>
    </el-upload>
    <el-upload v-else action="#" :accept="accept" :file-list="fileList" :limit="limit" :on-exceed="handleExceed"
      :http-request="upload" :on-remove="handleRemove" :on-preview="handlePreview" v-loading="loading"
      :disabled="viewImage">
      <el-button class="djsc_btn" type="primary" size="small" v-if="!viewImage">点击上传</el-button>
    </el-upload>
    <div slot="tip" class="el-upload__tip">
      {{ pageType && pageType == 'ocr' ? '请上传要识别的图片' : '上传文件不超过5MB 支持格式: .png、.jpg、.jpeg、.gif、.bmp' }}
    </div>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="90%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import {
  detailUpload,
  listUpload,
  uploadFile,
  delUpload,
  downloadFile
} from '@/api/user/file'

export default {
  data() {
    return {
      fileList: [],
      fileIdList: [],
      accept: '.png,.jpg,.jpeg,.gif,.bmp',
      dialogImageUrl: '',
      dialogVisible: false,
      loading: false,
      myValue: this.value
    }
  },
  name: "ImageUpload",
  props: {
    value: {
      type: String,
      default: ''
    },
    limit: {
      type: Number,
      default: 10
    },
    viewImage: {
      type: Boolean,
      default: false
    },
    pageType: String
  },
  mounted() {
    this.getUploadList()
  },
  methods: {
    getUploadList() {
      if (!this.myValue) {
        this.fileIds = [];
        this.fileList = [];
        return;
      };
      listUpload({
        fileIds: this.myValue
      }).then(res => {
        console.log(res)
        if (res.data.success) {
          this.fileList = [];
          res.data.result.forEach(f => {
            let file = f;
            console.log(file)
            file.name = file.fileRealName;
            this.fileList.push(file)
          })
          this.syncFileIdList()
        } else {
          console.log(res.data.message);
        }
      })
    },
    // 上传方法
    upload({ file }) {
      const formData = new FormData()
      formData.append('file', file)
      this.loading = true;
      uploadFile(formData)
        .then((res) => {
          if (res.data.success) {
            file.guid = res.data.result.guid
            this.fileList.push(file)
            this.syncFileIdList()
          } else {
            this.$msgError(res.data.message)
          }
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    // 移除文件
    handleRemove(file) {
      const that = this;
      this.$confirm('是否确认删除名称为"' + file.name + '"的文件吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {

        that.fileList.forEach((item, index) => {
          if (item.guid == file.guid) {
            that.fileList.splice(index, 1)
            that.loading = true
            delUpload({ guid: item.guid }).then((response) => {
              that.syncFileIdList()
              that.loading = false
            })
          }
        })
      })
    },
    /**
     * 预览
     * @param file
     */
    async handlePreview(file) {
      let data = {
        guid: file.guid
      }
      this.loading = true
      downloadFile(data).then((res) => {
        //download
        let url = window.URL.createObjectURL(new Blob([res.data]))
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        // link.setAttribute("download", file.name); // 自定义下载文件名（如exemple.txt）
        // document.body.appendChild(link);
        // link.click();

        //preview
        this.dialogImageUrl = url
        this.dialogVisible = true
        this.loading = false
      })
    },
    /**
     * 下载
     * @param file
     */
    async handleDownload(file) {
      let data = {
        guid: file.guid
      }
      this.loading = true
      downloadFile(data).then((res) => {
        let url = window.URL.createObjectURL(new Blob([res.data]))
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', file.name) // 自定义下载文件名（如exemple.txt）
        document.body.appendChild(link)
        link.click()
        this.loading = false
      })
    },
    handleExceed() {
      this.$message.warning('超出上传数量限制')
    },
    syncFileIdList() {
      this.fileIdList = this.fileList.map((item) => item.guid)
      this.$emit('syncFileIds', this.fileIdList.join(","))
    }
  },
  watch: {
    value(newValue) {
      this.myValue = newValue;
      this.getUploadList()
    }
  }
}
</script>

<style scoped lang="scss">
.el-upload__tip {
  padding-top: 10px;
  font-size: 12px;
  color: #c7cbd2;
  margin-top: 0;
}

.noneUpload {
  .el-upload {
    display: none;
  }

  .el-icon-plus {
    display: none;
  }

  .el-upload__tip {
    display: none;
  }

  .el-upload-list__item-status-label {
    display: none;
  }
}
</style>
