<template>
  <div class="app-container">
    <div style="width: 70%; margin: 20px auto">
      <el-steps :active="isPage">
        <el-step title="项目相关信息" description=""></el-step>
        <el-step title="等待审核" description=""></el-step>
        <el-step title="付款并提供签约资料" description=""></el-step>
        <el-step title="保函开立" description=""></el-step>
      </el-steps>
    </div>
    <div class="applyFor">
      <el-form
        style="background: #fff"
        ref="form"
        size="small"
        label-position="top"
        :model="formModel"
        v-loading="contentLoading"
        class="demo-ruleForm"
      >
        <div
          style="
            line-height: 40px;
            margin-bottom: 20px;
            border-bottom: 1px solid #eee;
            font-weight: 700;
          "
        >
          投标企业信息
        </div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="企业名称"
              prop="etpName"
              :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
            >
              <el-input
                size="small"
                v-model="formModel.etpName"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="社会信用代码"
              prop="etpUscc"
              :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
            >
              <el-input
                size="small"
                v-model="formModel.etpUscc"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="企业性质"
              prop="etpProperty"
              :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
            >
              <maindata
                v-model="formModel.etpProperty"
                dataKey="company-property"
                dataType="singleSelect"
              >
              </maindata>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="所属行业"
              prop="etpIndustry"
              :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
            >
              <maindata
                v-model="formModel.etpIndustry"
                dataKey="company-industry"
                dataType="tree"
              >
              </maindata>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="注册资本（万元）"
              prop="etpRegCapital"
              :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
            >
              <el-input
                size="small"
                v-model="formModel.etpRegCapital"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="证件签发日期"
              prop="etpCertEndDate"
              :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
            >
              <el-date-picker
                style="width: 48%"
                v-model="formModel.etpCertBeginDate"
                type="date"
                placeholder="选择开始日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
              <el-date-picker
                style="float: right; width: 48%"
                v-model="formModel.etpCertEndDate"
                type="date"
                placeholder="选择结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="注册地址"
              prop="etpArea"
              :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
            >
              <addressPage
                :etpRegAddress="etpRA2"
                @addr-sent="initAddr2"
              ></addressPage>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="营业执照"
              prop="etpIdcard"
              :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
            >
              <upLoadImg
                v-model="formModel.etpIdcard"
                :limit="1"
                @syncFileIds="setEtpIdcard2"
              >
              </upLoadImg>
            </el-form-item>
          </el-col>
        </el-row>
        <div
          style="
            line-height: 40px;
            margin-bottom: 20px;
            border-bottom: 1px solid #eee;
            font-weight: 700;
          "
        >
          法人信息
        </div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="法人姓名"
              prop="etpLegal"
              :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
            >
              <el-input
                size="small"
                v-model="formModel.etpLegal"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="法人证件类型"
              prop="etpLegalIdcardType"
              :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
            >
              <el-select
                style="width: 100%"
                disabled
                v-model="formModel.etpLegalIdcardTypec"
                placeholder="身份证"
              >
                <el-option key="身份证" label="身份证" value="身份证">
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item
              label="法人身份证号"
              prop="etpLegalIdcardCode"
              :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
            >
              <el-input
                size="small"
                v-model="formModel.etpLegalIdcardCode"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="法人身份证有效期"
              prop="etpLegalIdcardDateBegin"
              :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
            >
              <el-date-picker
                style="width: 48%"
                v-model="formModel.etpLegalIdcardDateBegin"
                type="date"
                placeholder="选择开始日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
              <el-date-picker
                style="float: right; width: 48%"
                v-model="formModel.etpLegalIdcardDateEnd"
                type="date"
                placeholder="选择结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="法人正面证件照"
              prop="etpLegalIdcardPicZm"
              :rules="[{ required: true, message: '请上传', trigger: 'blur' }]"
            >
              <upLoadImg
                v-model="formModel.etpLegalIdcardPicZm"
                :limit="1"
                @syncFileIds="setEtpLegalIdcardPicZm"
              >
              </upLoadImg>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="法人手机号"
              prop="etpLegalPhone"
              :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
            >
              <el-input
                maxlength="11"
                size="small"
                v-model="formModel.etpLegalPhone"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="法人背面证件照"
              prop="etpLegalIdcardPicFm"
              :rules="[{ required: true, message: '请上传', trigger: 'blur' }]"
            >
              <upLoadImg
                v-model="formModel.etpLegalIdcardPicFm"
                :limit="1"
                @syncFileIds="setEtpLegalIdcardPicFm"
              >
              </upLoadImg>
            </el-form-item>
          </el-col>
        </el-row>
        <div
          style="
            border-bottom: 1px solid #eee;
            font-weight: 700;
            margin-bottom: 10px;
            padding: 10px 0;
          "
        >
          项目信息
        </div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="项目名称"
              prop="projectName"
              :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
            >
              <el-input v-model="formModel.projectName" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="项目编号" prop="projectCode">
              <el-input v-model="formModel.projectCode" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="担保金额（人民币）："
              prop="guaranteeMoney"
              :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
            >
              <el-input
                oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                v-model="formModel.guaranteeMoney"
                placeholder="元"
                @input="guaranteeMoneyBlur"
                @blur="guaranteeMoneyBlur"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="保函受益人（甲方/招标方名称）"
              prop="bidCompanyName"
              :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
            >
              <el-input
                v-model="formModel.bidCompanyName"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="经办人姓名"
              prop="operator"
              :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
            >
              <el-input v-model="formModel.operator" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="经办人手机号"
              prop="operatorPhone"
              :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
            >
              <el-input
                maxlength="11"
                v-model="formModel.operatorPhone"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="保函有效期" prop="guaranteeDays">
              <div style="display: flex; align-items: center; height: 35px">
                <el-radio-group
                  @input="ridioBtn"
                  v-model="radio"
                  style="flex: 1"
                >
                  <el-radio :label="1">
                    <template>
                      <el-input
                        style="width: 150px"
                        onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                        v-model="formModelTmp.guaranteeDays"
                        placeholder="请输入"
                        :disabled="isRadiodisabled"
                      />
                      <span style="padding-left: 5px">月</span>
                    </template></el-radio
                  >
                  <el-radio :label="0"
                    >有效期至
                    <el-date-picker
                      style="width: 48%"
                      v-model="formModel.guaranteeEndDate"
                      type="date"
                      placeholder="选择日期"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      :disabled="!isRadiodisabled"
                    >
                    </el-date-picker
                  ></el-radio>
                </el-radio-group>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="保函邮寄地址"
              prop="guaranteePostArea"
              :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
            >
              <addressPage
                :etpRegAddress="etpRA"
                @addr-sent="initAddr"
              ></addressPage>
            </el-form-item>
          </el-col>
<el-col :span="12">
            <el-form-item label="需至少提供两个文件" prop="bidDocument">
              <p style="color: #666">中标通知书</p>
              <uploadBtn
                v-model="formModel.winBiderDocument"
                @syncFileIds="winBiderDocumentBtn"
                :accept="bidFileType"
              >
              </uploadBtn>
              <p style="color: #666">招标文件</p>
              <uploadBtn
                v-model="formModel.bidDocument"
                @syncFileIds="bidDocumentBtn"
                :accept="bidFileType"
              >
              </uploadBtn>
              <p style="color: #666">合同</p>
              <uploadBtn
                v-model="formModel.bidContract"
                @syncFileIds="bidContractBtn"
                :accept="bidFileType"
              >
              </uploadBtn>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="选择保函文本（选择后点击图标预览）"
              prop="guaranteeDocument"
              :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
            >
              <maindata
                v-if="formModel.guaranteeCategory === '01'"
                v-model="formModel.guaranteeDocument"
                dataKey="guarantee-document"
                dataType="singleSelect"
              >
              </maindata>
              <maindata
                v-else-if="formModel.guaranteeCategory === '02'"
                v-model="formModel.guaranteeDocument"
                dataKey="guarantee-document-ly"
                dataType="singleSelect"
              >
              </maindata>
              <maindata
                v-else-if="formModel.guaranteeCategory === '03'"
                v-model="formModel.guaranteeDocument"
                dataKey="guarantee-document-zl"
                dataType="singleSelect"
              >
              </maindata>
              <maindata
                v-else-if="formModel.guaranteeCategory === '04'"
                v-model="formModel.guaranteeDocument"
                dataKey="guarantee-document-yfk"
                dataType="singleSelect"
              >
              </maindata>
              <div
                v-if="formModel.guaranteeDocument == '01'"
                style="
                  text-align: left;
                  padding-top: 20px;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                "
                @click="pdfBtn"
              >
                <img
                  class="yulan_img"
                  src="../../../assets/img/Pdf.png"
                  alt=""
                />
                <span
                  style="font-size: 14px; padding-left: 15px; color: #3d8bfc"
                  >保函文本（通用）点击预览</span
                >
              </div>
              <div
                v-if="formModel.guaranteeDocument == '02'"
                style="
                  text-align: left;
                  padding-top: 20px;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                "
                @click="pdfBtn"
              >
                <img
                  class="yulan_img"
                  src="../../../assets/img/Pdf.png"
                  alt=""
                />
                <span
                  v-if="formModel.guaranteeCategory === '02'"
                  style="font-size: 14px; padding-left: 15px; color: #3d8bfc"
                  >保函文本（独立-见索即付）点击预览</span
                >
                <span
                  v-else
                  style="font-size: 14px; padding-left: 15px; color: #3d8bfc"
                  >保函文本（独立）点击预览</span
                >
              </div>
            </el-form-item>
          </el-col>
          <el-col v-if="formModel.guaranteeDocument == '03'" :span="12">
            <el-form-item
              label="请上传自定义保函文本"
              prop="guaranteeDocumentExt"
              :rules="[{ required: true, message: '请上传', trigger: 'blur' }]"
            >
              <uploadBtn
                v-model="formModel.guaranteeDocumentExt"
                @syncFileIds="setEtpIdcard3"
                :accept="bidFileType3"
              >
              </uploadBtn>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="我要给客服留言" prop="remark">
              <el-input v-model="formModel.remark" placeholder="请输入" />
            </el-form-item>
          </el-col>
        </el-row>
        <div
          style="padding: 20px; text-align: center; border-top: 1px solid #eee"
        >
          <template>
            <!-- <div v-if="formModel.guaranteeMoney" style="font-size: 24px">
              当下操作预计保函手续费为{{
                isRmbMsg
              }}元，若有特殊情况客服会与您取得联系
            </div> -->
            <div style="font-size: 20px; padding: 10px 0">
              提交即表示同意
              <span
                style="color: #3d8bfc; cursor: pointer"
                @click="goUserAgreement"
                >《线上保函协议》</span
              >
            </div>
            <div style="font-size: 18px; padding-bottom: 20px">
              有问题请拨打:400-895-8805
            </div>
          </template>
          <el-button
            style="width: 500px; height: 40px; font-size: 20px"
            size="small"
            type="primary"
            @click="submitForm('form')"
            >提交</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  getApplyZtb,
  getUpdateZtb,
  getDetailZtb,
  getDetailC
} from '@//api/system/applyFor'
import maindata from '@/components/MainData/index.vue'
import uploadBtn from '@/components/uploadBtn/index.vue'
import addressPage from '@/components/MainData/mainDataAddr.vue'
import { parseTime } from '@/utils/DateUtils'
import upLoadImg from '@/components/uploadImg/index.vue'
export default {
  components: { uploadBtn, addressPage, maindata, upLoadImg },
  data() {
    return {
      isFixSignDocs: 0,
      isRadiodisabled: false,
      radio: 1,
      etpLegalIdcard: '.png,.jpg,.jpeg',
      bidFileType3: '.doc,.docx,.pdf',
      isPage: 0,
      bidFileType: '.doc,.docx,.zip,.rar,.pdf',
      companyInfo: {},
      upload: {
        limit: 1,
        viewImage: true
      },
      contentLoading: false,
      guaranteeId: '',
      formModelTmp:{
          guaranteeDays:undefined,
      },
      formModel: {
        // 企业信息
        etpUserId: '',
        returnGuaranteeOrg: 'ccb', // 中国建设银行
        etpName: '', // 企业名称
        etpUscc: '', // 社会信用代码
        etpProperty: '', // 企业性质
        etpIndustry: '', // 所属行业
        etpRegCapital: '', // 注册资本
        etpCertBeginDate: '', // 证件开始日期
        etpCertEndDate: '', // 证件结束日期
        etpIdcard: '', // 营业执照
        etpProvince: '', // 注册省
        etpCity: '', // 注册市
        etpArea: '', // 注册区/县
        etpStreet: '', // 注册街道
        etpAddr: '', // 注册详细
        // 法人信息
        etpLegal: '', // 法人姓名
        etpLegalIdcardType: '身份证', // 法人证件类型
        etpLegalIdcardCode: '', // 法人证件号码
        etpLegalIdcardDateBegin: '', // 法人证件开始日期
        etpLegalIdcardDateEnd: '', // 法人证件结束日期
        etpLegalPhone: '', // 法人手机号
        etpLegalIdcardPicFm: '', // 法人证件照（反面）
        etpLegalIdcardPicZm: '', // 法人证件照（正面）
        // 项目信息
        operator: '', // 经办人
        operatorPhone: '', // 经办人手机
        guaranteeId: '',
        guaranteeCategory: '', // 保函类型 01:投标保函;02:履约保函;03:质量保函;04:预付款保函
        guaranteeType: 0, // 0:银行保函 1：商业保函 2：保险保函
        bidCompanyName: '', // 保函受益人
        bidContract: '', // 合作协议(合作合同，自动生成合同编号)
        bidDocument: '', // 招标文件
        guaranteeDocument: '', // 保函文本（通用/独立/自定义
        guaranteeDocumentExt: '', // 自定义保函文本上传的id
        guaranteeMoney: '', // 保函金额
        guaranteeDays: '', // 有效期天数
        guaranteeEndDate: '', // 有效期至
        guaranteePayMoney: '', // 支付金额
        guaranteePostProvince: '', // 保函邮寄地址所在省/直辖市
        guaranteePostCity: '', // 保函邮寄地址所在市
        guaranteePostArea: '', // 保函邮寄地址所在区县
        guaranteePostAddr: '', // 保函邮寄地址所在镇
        guaranteePostStreet: '', // 保函邮寄地址所在街道/镇
        projectCode: '', // 项目编码
        projectName: '', // 项目名称
        winBiderDocument: '' // 中标通知书
      },
      etpRA: {
        province: '',
        city: '',
        area: '',
        addr: '',
        street: ''
      },
      etpRA2: {
        province: '',
        city: '',
        area: '',
        addr: '',
        street: ''
      }, // 注册地址
      isRmbMsg: 0
    }
  },
  mounted() {
    this.formModel.etpUserId = this.$route.query.userId
    this.formModel.guaranteeCategory = this.$route.query.guaranteeCategory
    this.guaranteeId = this.$route.query.guaranteeId
    this.formModel.guaranteeId = this.$route.query.guaranteeId
    if (this.formModel.guaranteeId) {
      this.getDetailData()
    } else {
      this.getComanyInfo()
    }
  },
  methods: {
    ridioBtn() {
      // 保函有效期
      if (this.radio == 1) {
        this.isRadiodisabled = false
      } else {
        this.isRadiodisabled = true
      }
    },
    guaranteeMoneyBlur() {
      // 担保金额失去焦点事件
      this.isRmbMsg = (this.formModel.guaranteeMoney / 100).toFixed(2)
      if (this.isRmbMsg < 500) {
        this.isRmbMsg = 500
      }
    },
    getComanyInfo() {
      // 获取公司信息
      this.contentLoading = true
      getDetailC({ userId: this.formModel.etpUserId }).then((res) => {
        if (res.data.success) {
          this.formModel.etpName = res.data.result.etpName
          this.formModel.etpProperty = res.data.result.etpProperty
          this.formModel.etpUscc = res.data.result.etpUscc
          this.formModel.etpIndustry = res.data.result.etpIndustry
          this.formModel.etpRegCapital = res.data.result.etpRegCapital
          this.formModel.etpCertBeginDate = res.data.result.etpCertBeginDate
          this.formModel.etpCertEndDate = res.data.result.etpCertEndDate
          this.formModel.etpIdcard = res.data.result.etpIdcard
          this.formModel.etpProvince = res.data.result.etpProvince
          this.formModel.etpCity = res.data.result.etpCity
          this.formModel.etpArea = res.data.result.etpArea
          this.formModel.etpStreet = res.data.result.etpStreet
          this.formModel.etpAddr = res.data.result.etpAddr
          this.formModel.etpLegal = res.data.result.etpLegal
          this.formModel.etpLegalIdcardType = '身份证'
          this.formModel.etpLegalPhone = res.data.result.etpLegalPhone
          this.formModel.etpLegalIdcardPicZm =
            res.data.result.etpLegalIdcardPicZm
          this.formModel.etpLegalIdcardPicFm =
            res.data.result.etpLegalIdcardPicFm
          this.formModel.etpLegalIdcardCode = res.data.result.etpLegalIdcardCode
          this.formModel.etpLegalIdcardDateBegin =
            res.data.result.etpLegalIdcardDateBegin
          this.formModel.etpLegalIdcardDateEnd =
            res.data.result.etpLegalIdcardDateEnd

          this.formModel.etpLegalIdcardDateBegin = parseTime(
            this.formModel.etpLegalIdcardDateBegin,
            '{y}-{m}-{d}'
          )
          this.formModel.etpLegalIdcardDateEnd = parseTime(
            this.formModel.etpLegalIdcardDateEnd,
            '{y}-{m}-{d}'
          )
          this.formModel.etpCertBeginDate = parseTime(
            this.formModel.etpCertBeginDate,
            '{y}-{m}-{d}'
          )
          this.formModel.etpCertEndDate = parseTime(
            this.formModel.etpCertEndDate,
            '{y}-{m}-{d}'
          )
          this.etpRA2 = {
            province: this.formModel.etpProvince,
            city: this.formModel.etpCity,
            area: this.formModel.etpArea,
            street: this.formModel.etpStreet,
            addr: this.formModel.etpAddr
          }
        } else {
          this.$message.error(res.data.message)
        }
        this.contentLoading = false
      })
    },
    pdfBtn() {
      switch (this.formModel.guaranteeCategory) {
        case '01':
          if (this.formModel.guaranteeDocument === '01') {
            window.open(
              'https://api.yinbaobiao.com/web/ybb/upload/document-doc/tb-common.pdf',
              '_blank'
            )
          } else if (this.formModel.guaranteeDocument === '02') {
            window.open(
              'https://api.yinbaobiao.com/web/ybb/upload/document-doc/tb-alone-guarantee.pdf',
              '_blank'
            )
          }
          break
        case '02':
          if (this.formModel.guaranteeDocument === '01') {
            window.open(
              'https://api.yinbaobiao.com/web/ybb/upload/document-doc/guarantee-doc-ly-common.pdf',
              '_blank'
            )
          } else if (this.formModel.guaranteeDocument === '02') {
            window.open(
              'https://api.yinbaobiao.com/web/ybb/upload/document-doc/guarantee-doc-ly-jsjf.pdf',
              '_blank'
            )
          }
          break
        case '03':
          if (this.formModel.guaranteeDocument === '01') {
            window.open(
              'https://api.yinbaobiao.com/web/ybb/upload/document-doc/guarantee-doc-zl-common.pdf',
              '_blank'
            )
          } else if (this.formModel.guaranteeDocument === '02') {
            window.open(
              'https://api.yinbaobiao.com/web/ybb/upload/document-doc/guarantee-doc-zl-alone.pdf',
              '_blank'
            )
          }
          break
        case '04':
          if (this.formModel.guaranteeDocument === '01') {
            window.open(
              'https://api.yinbaobiao.com/web/ybb/upload/document-doc/guarantee-doc-yfk-common.pdf',
              '_blank'
            )
          } else if (this.formModel.guaranteeDocument === '02') {
            window.open(
              'https://api.yinbaobiao.com/web/ybb/upload/document-doc/guarantee-doc-yfk-alone.pdf',
              '_blank'
            )
          }
          break
      }
    },
    initAddr2(originAddr) {
      // 企业地址
      this.formModel.etpProvince = originAddr.province
      this.formModel.etpCity = originAddr.city
      this.formModel.etpArea = originAddr.area
      this.formModel.etpStreet = originAddr.street
      this.formModel.etpAddr = originAddr.addr
    },
    initAddr(originAddr) {
      // 项目地址
      this.formModel.guaranteePostProvince = originAddr.province
      this.formModel.guaranteePostCity = originAddr.city
      this.formModel.guaranteePostArea = originAddr.area
      this.formModel.guaranteePostAddr = originAddr.addr
      this.formModel.guaranteePostStreet = originAddr.street
    },
    sysncCompanyIdcard(v) {
      this.formModel.companyIdcard = v
    },
    submitForm(formName) {
      if (typeof this.formModel.etpCertBeginDate === 'string') {
        this.formModel.etpCertBeginDate = Date.parse(
          this.formModel.etpCertBeginDate
        )
        this.formModel.etpCertEndDate = Date.parse(
          this.formModel.etpCertEndDate
        )
      }
      if (typeof this.formModel.etpLegalIdcardDateBegin === 'string') {
        //  法人证件日期
        this.formModel.etpLegalIdcardDateBegin = Date.parse(
          this.formModel.etpLegalIdcardDateBegin
        )
        this.formModel.etpLegalIdcardDateEnd = Date.parse(
          this.formModel.etpLegalIdcardDateEnd
        )
      }
      if (typeof this.formModel.guaranteeEndDate === 'string') {
        this.formModel.guaranteeEndDate = Date.parse(
          this.formModel.guaranteeEndDate
        )
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let apiUrl = ''
          if (this.guaranteeId) {
            // 更新
            console.log('更新')
            apiUrl = getUpdateZtb
          } else {
            // 新增
            console.log('新增')
            apiUrl = getApplyZtb
          }
          if(this.radio===1){
              this.formModel.guaranteeEndDate=0
              this.formModel.guaranteeDays=this.formModelTmp.guaranteeDays*30
          }else{
              this.formModel.guaranteeDays=0
          }
          apiUrl(this.formModel).then(
            (res) => {
              if (res.data.success) {
                let guaranteeId = ''
                if (this.guaranteeId) {
                  guaranteeId = this.guaranteeId
                } else {
                  guaranteeId = res.data.result.guaranteeId
                }

                this.$message.success(res.data.message)
                this.$router.push({
                  path: '/applyForMore/Detail',
                  query: {
                    type: 1,
                    guaranteeId: guaranteeId
                  }
                })
              } else {
                console.log(res.data.message)
                this.$message.error(res.data.message)
              }
            }
          )
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getDetailData() {
      // 获取详情
      this.contentLoading = true
      const data = {
        guaranteeId: this.formModel.guaranteeId
      }
      getDetailZtb(data).then((res) => {
        this.contentLoading = false
        if (res.data.success) {
          this.formModel = res.data.result
          //有效期判断
          if (res.data.result.guaranteeDays) {
            this.formModel.guaranteeDays = res.data.result.guaranteeDays / 30
             this.formModelTmp.guaranteeDays=this.formModel.guaranteeDays
            this.radio=1
            this.isRadiodisabled=false
          }else{
            this.radio=0
            this.isRadiodisabled=true
          }
          this.formModel.guaranteeEndDate =
            parseTime(this.formModel.guaranteeEndDate, '{y}-{m}-{d}') || ''
          this.formModel.guaranteeId = this.guaranteeId
          this.formModel.etpCertBeginDate = parseTime(
            this.formModel.etpCertBeginDate,
            '{y}-{m}-{d}'
          )
          this.formModel.etpCertEndDate = parseTime(
            this.formModel.etpCertEndDate,
            '{y}-{m}-{d}'
          )
          // 法人开始日期
          this.formModel.etpLegalIdcardDateBegin = parseTime(
            this.formModel.etpLegalIdcardDateBegin,
            '{y}-{m}-{d}'
          )
          // 法人证件结束日期
          this.formModel.etpLegalIdcardDateEnd = parseTime(
            this.formModel.etpLegalIdcardDateEnd,
            '{y}-{m}-{d}'
          )
          this.etpRA = {
            province: this.formModel.guaranteePostProvince,
            city: this.formModel.guaranteePostCity,
            area: this.formModel.guaranteePostArea,
            addr: this.formModel.guaranteePostAddr,
            street: this.formModel.guaranteePostStreet
          }
          this.etpRA2 = {
            province: this.formModel.etpProvince,
            city: this.formModel.etpCity,
            area: this.formModel.etpArea,
            addr: this.formModel.etpAddr,
            street: this.formModel.etpStreet
          }
          this.guaranteeMoneyBlur()
        } else {
          this.$message.error(res.data.message)
        }
        this.contentLoading = false
      })
    },
    gobidbond() {
      this.$router.push('/bidBondAdmin')
    },
    winBiderDocumentBtn(v) {
      // 中标通知书
      this.formModel.winBiderDocument = v
    },
    bidDocumentBtn(v) {
      // 招标文件
      this.formModel.bidDocument = v
    },
    bidContractBtn(v) {
      // 合同
      this.formModel.bidContract = v
    },
    setEtpIdcard(v) {
      // 文件上传
      this.formModel.bidDocument = v
    },
    setEtpIdcard2(v) {
      // 营业执照上传
      this.formModel.etpIdcard = v
    },
    setEtpLegalIdcardPicZm(v) {
      // 法人证件正面照片
      this.formModel.etpLegalIdcardPicZm = v
    },
    setEtpLegalIdcardPicFm(v) {
      // 法人证件背面照片
      this.formModel.etpLegalIdcardPicFm = v
    },
    setEtpIdcard3(v) {
      this.formModel.guaranteeDocumentExt = v
    },
    goUserAgreement() {
      // 用户协议
      window.open('/privacyAgreement', '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
.applyFor {
  width: 1200px;
  margin: 0 auto;
}

::v-deep .el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0 0;
}

.edit_box {
  cursor: pointer;
  color: #3d8bfc;
  float: right;
  font-weight: normal;
  font-size: 14px;
}

.yulan_img {
  width: 50px;
  height: 50px;
}

.left_title {
  width: 1200px;
  margin: 20px auto;
  span {
    cursor: pointer;
    color: #3d8bfc;
  }
}
</style>
