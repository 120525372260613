<template>
  <div class="app-container">
    <div style="width: 70%; margin: 20px auto">
      <el-steps :active="2">
        <el-step title="项目相关信息" description=""></el-step>
        <el-step title="等待审核" description=""></el-step>
        <el-step title="付款并提供签约资料" description=""></el-step>
        <el-step title="保函开立" description=""></el-step>
      </el-steps>
    </div>
    <div class="applyFor">
      <div class="title"><h style="font-weight:bold;font-size:18px">签约资料</h> <span @click="downloadAllSigned()" >一键下载所有签约资料>></span></div>
      <el-form
        style="background: #fff"
        ref="form"
        size="small"
        label-position="top"
        :model="formModel"
        class="demo-ruleForm"
        v-loading="contentLoading"
        
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="企业简介文件">
              <uploadBtn
                v-model="formModel.signIntroFileId"
                @syncFileIds="signIntroFileIdBtn"
                :accept="bidFileType"
                :viewImage="upload.view"
                :limit="upload.limit"
              >
              </uploadBtn> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="企业章程">
              <uploadBtn
                v-model="formModel.signAcFileId"
                @syncFileIds="signAcFileIdBtn"
                :accept="bidFileType"
                :viewImage="upload.view"
                :limit="upload.limit"
              >
              </uploadBtn> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="审计报告">
              <uploadBtn
                v-model="formModel.signAdReportFileId"
                @syncFileIds="signAdReportFileIdBtn"
                :accept="bidFileType"
                :viewImage="upload.view"
                :limit="upload.limit"
              >
              </uploadBtn> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="近三个月财报">
              <uploadBtn
                v-model="formModel.signFcReportFileId"
                @syncFileIds="signFcReportFileIdBtn"
                :accept="bidFileType"
                :viewImage="upload.view"
                :limit="upload.limit"
              >
              </uploadBtn> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="代理人授权书">
              <uploadBtn
                v-model="formModel.signAgtCertificateFileId"
                @syncFileIds="signAgtCertificateFileIdBtn"
                :accept="bidFileType"
                :viewImage="upload.view"
                :limit="upload.limit"
              >
              </uploadBtn> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="代理人身份证明">
              <uploadBtn
                v-model="formModel.signAgtIdcardFileId"
                @syncFileIds="signAgtIdcardFileIdBtn"
                :accept="bidFileType"
                :viewImage="upload.view"
                :limit="upload.limit"
              >
              </uploadBtn> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="资质证书（可传多个）">
              <uploadBtn
                v-model="formModel.signQcFileIds"
                @syncFileIds="signQcFileIdsBtn"
                :accept="bidFileType"
                  :viewImage="upload.view"
              >
              </uploadBtn> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="其他资料（可传多个）">
              <uploadBtn
                v-model="formModel.signAdditionalFileIds"
                @syncFileIds="signAdditionalFileIdsBtn"
                :accept="bidFileType"
                  :viewImage="upload.view"
              >
              </uploadBtn> </el-form-item
          ></el-col>
        </el-row>
        <div class="title">
          以下资料请先下载模版，填写后再上传<span @click="downAllBtn"
            >一键下载所有模版>></span
          >
        </div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item>
              <div class="list_title">
                法人代表证明书：<span @click="downBtn(1)">点击下载模版</span>
              </div>
              <uploadBtn
                v-model="formModel.signLegalCertificateFileId"
                @syncFileIds="signLegalCertificateFileIdBtn"
                :accept="bidFileType"
                :viewImage="upload.view"
                :limit="upload.limit"
              >
              </uploadBtn> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item>
              <div class="list_title">
                征信授权查询函<span @click="downBtn(2)">点击下载模版</span>
              </div>
              <uploadBtn
                v-model="formModel.signCreditQueryFileId"
                @syncFileIds="signCreditQueryFileIdBtn"
                :accept="bidFileType"
                :viewImage="upload.view"
                :limit="upload.limit"
              >
              </uploadBtn> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item>
              <div class="list_title">
                决议：<span @click="downBtn(3)">点击下载模版</span>
              </div>
              <uploadBtn
                v-model="formModel.signDecideFileId"
                @syncFileIds="signDecideFileIdBtn"
                :accept="bidFileType"
                :viewImage="upload.view"
                :limit="upload.limit"
              >
              </uploadBtn> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item>
              <div class="list_title">
                注销保函承诺书：<span @click="downBtn(4)">点击下载模版</span>
              </div>
              <uploadBtn
                v-model="formModel.signGuaranteeCancelLetterId"
                @syncFileIds="signGuaranteeCancelLetterIdBtn"
                :accept="bidFileType"
                :viewImage="upload.view"
                :limit="upload.limit"
              >
              </uploadBtn> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item>
              <div class="list_title">
                查询函：<span @click="downBtn(5)">点击下载模版</span>
              </div>
              <uploadBtn
                v-model="formModel.signInquiryLetterFileId"
                @syncFileIds="signInquiryLetterFileIdBtn"
                :accept="bidFileType"
                :viewImage="upload.view"
                :limit="upload.limit"
              >
              </uploadBtn> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item>
              <div class="list_title">
                出具委托式保函承诺书：<span @click="downBtn(6)"
                  >点击下载模版</span
                >
              </div>
              <uploadBtn
                v-model="formModel.signGuaranteeCommitLetterId"
                @syncFileIds="signGuaranteeCommitLetterIdBtn"
                :accept="bidFileType"
                :viewImage="upload.view"
                :limit="upload.limit"
              >
              </uploadBtn> </el-form-item
          ></el-col>
        </el-row>
        <div class="title"></div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="打包上传全部资料">
              <uploadBtn
                v-model="formModel.signAllFileId"
                @syncFileIds="signAllFileIdBtn"
                :accept="bidFileType2"
                :viewImage="upload.view"
                :limit="upload.limit"
              >
              </uploadBtn> </el-form-item></el-col
        ></el-row>
        <div style="padding: 20px; text-align: center; border-top: 1px solid #eee" v-if="!this.upload.view">
      <template>
        <div style="font-size: 20px; padding: 10px 0">
          提交即表示同意
          <span style="color: #3d8bfc; cursor: pointer" @click="goUserAgreement"
            >《线上保函协议》</span
          >
        </div>
        <div style="font-size: 18px; padding-bottom: 20px">
          有问题请拨打:400-895-8805
        </div>
        <el-button
          style="width: 500px; height: 40px; font-size: 20px"
          size="small"
          type="primary"
          @click="submitForm('form')"
          >提交</el-button
        >
      </template>
    </div>
      </el-form>

    </div>
    
  </div>
</template>

<script>
import uploadBtn from '@/components/uploadBtn/index.vue'
import { getaddSignFiles,getDetailZtb } from '@/api/system/applyFor'
import {downLoadAllSignedFiles} from '@/api/guarantee/guarantee'
export default {
  components: { uploadBtn },
  data() {
    return {
      contentLoading:false,
      upload:{
        view: false,
        limit:1
      },
      bidFileType: '.doc,.docx,.zip,.rar,.pdf',
      bidFileType2: '.zip,.rar',
      formModel: {
        guaranteeId: '',
        signIntroFileId: '', // 企业简介文件ID
        signAcFileId: '', // 企业章程文件ID
        signAdReportFileId: '', // 审计报告文件ID
        signFcReportFileId: '', // 近三个月财报文件
        signAgtCertificateFileId: '', // 代理人授权书文件ID
        signAgtIdcardFileId: '', // 代理人身份证正反面文件ID
        signQcFileIds: '', // 资质证书文件ID
        signAdditionalFileIds: '', // 其他资料ID
        signLegalCertificateFileId: '', // 法人代表证明书文件ID
        signCreditQueryFileId: '', // 征信授权查询函文件ID
        signDecideFileId: '', // 决议文件ID
        signGuaranteeCancelLetterId: '', // 注销保函承诺书文件ID
        signInquiryLetterFileId: '', // 查询函文件ID
        signGuaranteeCommitLetterId: '', // 出具委托式保函承诺书文件ID
        signGuaranteeContractFileId: '', // 委托保证担保合同文件ID
        signAllFileId: '' // 打包上传全部
      }
    }
  },
  mounted() {
    this.formModel.guaranteeId = this.$route.query.guaranteeId
    this.upload.view=this.$route.query.view==='true'?true:false
    this.getDetailData()
  },
  methods: {
    getDetailData() {
      // 获取详情
      this.contentLoading = true
      getDetailZtb({ guaranteeId: this.formModel.guaranteeId }).then((res) => {
        this.contentLoading = false
        if (res.data.success) {
          this.formModel = res.data.result
        } else {
          this.$message.error(res.data.message)
        }
        this.contentLoading = false
      })
    },
    signIntroFileIdBtn(v) {
      // 企业简介
      this.formModel.signIntroFileId = v
    },
    signAcFileIdBtn(v) {
      // 企业章程文件
      this.formModel.signAcFileId = v
    },
    signAdReportFileIdBtn(v) {
      // 审计报告文件
      this.formModel.signAdReportFileId = v
    },
    signFcReportFileIdBtn(v) {
      // 近三个月财报文件
      this.formModel.signFcReportFileId = v
    },
    signAgtCertificateFileIdBtn(v) {
      // 代理人授权书
      this.formModel.signAgtCertificateFileId = v
    },
    signAgtIdcardFileIdBtn(v) {
      // 代理人身份证明
      this.formModel.signAgtIdcardFileId = v
    },
    signQcFileIdsBtn(v) {
      // 资质证书
      this.formModel.signQcFileIds = v
    },
    signAdditionalFileIdsBtn(v) {
      // 其他资料
      this.formModel.signAdditionalFileIds = v
    },
    signLegalCertificateFileIdBtn(v) {
      // 法人代表证明书
      this.formModel.signLegalCertificateFileId = v
    },
    signCreditQueryFileIdBtn(v) {
      // 征信授权查询函
      this.formModel.signCreditQueryFile = v
    },
    signDecideFileIdBtn(v) {
      // 决议
      this.formModel.signDecideFileId = v
    },
    signGuaranteeCancelLetterIdBtn(v) {
      // 注销保函承诺书
      this.formModel.signGuaranteeCancelLetterId = v
    },
    signInquiryLetterFileIdBtn(v) {
      // 查询函
      this.formModel.signCreditQueryFile = v
    },
    signGuaranteeCommitLetterIdBtn(v) {
      // 出具委托式保函承诺书
      this.formModel.signGuaranteeCommitLetterId = v
    },
    signAllFileIdBtn(v) {
      // 征信授权查询函
      this.formModel.signAllFileId = v
    },
    gobidbond() {
      this.$router.push('/bidBondAdmin')
    },
    goUserAgreement() {
      // 用户协议
      window.open('/privacyAgreement', '_blank')
    },
    //一键下载所有签约资料
    downloadAllSigned(){
      this.contentLoading=true
         const data={
          guaranteeId:this.formModel.guaranteeId
         }
         
         downLoadAllSignedFiles(data).then(res => {
          this.contentLoading=false
          console.log('压缩包打印数据')
          // console.log(res)
          const content = res.data
          const blob = new Blob([content], { type: 'application/zip' })
          const fileName = '签约资料'
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href) // 释放URL 对象
          document.body.removeChild(elink)
        })
    },
    downAllBtn() {
      // 一键下载全部文件
      window.open(
          'https://api.yinbaobiao.com/web/ybb/upload/zgc_ccb_0707.zip',
          '_blank'
        )
    },
    downBtn(v) {
      if (v === 1) {
        // 法人代表证明书
        window.open(
          'https://api.yinbaobiao.com/web/ybb/upload/zgc_ccb_0707/fddbrzms_0707.docx',
          '_blank'
        )
      } else if (v === 2) {
        // 征信授权查询函
        window.open(
          'https://api.yinbaobiao.com/web/ybb/upload/zgc_ccb_0707/zxsqcxh_tpl_0707.zip',
          '_blank'
        )
      } else if (v === 3) {
        // 决议
         window.open(
          'https://api.yinbaobiao.com/web/ybb/upload/zgc_ccb_0707/jueyi_0707.doc',
          '_blank'
        )
      } else if (v === 4) {
        // 注销保函承诺书
        window.open(
          'https://api.yinbaobiao.com/web/ybb/upload/zgc_ccb_0707/zxbhcns_0707.docx',
          '_blank'
        )
      } else if (v === 5) {
        // 查询函
        window.open(
          'https://api.yinbaobiao.com/web/ybb/upload/zgc_ccb_0707/cxh_tpl_0707.docx',
          '_blank'
        )
      } else if (v === 6) {
        // 出具委托式保函承诺书
         window.open(
          'https://api.yinbaobiao.com/web/ybb/upload/zgc_ccb_0707/cjwtscns_0707.docx',
          '_blank'
        )
      }
    },
    submitForm() {
      // 提交
      getaddSignFiles(this.formModel).then((res) => {
        if (res.data.success) {
          this.$message.success(res.data.message)
        } else {
          this.$message.error(res.data.message)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.qy{
      text-align: center;
    font-weight: bold;
    cursor: pointer;
    font-size: 18px;
}
.left_title,
.applyFor {
  width: 1200px;
  margin: 0 auto;
  .title {
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    padding-bottom: 10px;
    overflow: hidden;
    span {
      color: #1890ff;
      cursor: pointer;
      padding-left: 10px;
      font-weight: bold;
    }
  }
  .list_title {
    color: #606266;
    line-height: 40px;
    span {
      color: #1890ff;
      cursor: pointer;
      font-weight: bold;
    }
  }
}
</style>
