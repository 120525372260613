<template>
  <span class="send-sms-code" type="text" @click="handleSendSMSCode" :disabled="Boolean(countDown)" size="small">
    {{ message }}
  </span>
</template>

<script>
import { uasSendSMSCode } from "@/api/system/applyFor";

export default {
  name: "SendSmsCode",

  props: {
    telephone: {
      type: String,
      required: true,
      default: "",
    },
    smsType: {
      type: String,
      required: false,
      default: "login",
    },
  },

  data() {
    return {
      message: "获取验证码",
      countDown: 0,
      intervalId: -1,
      loading: false,
    };
  },

  methods: {
    handleSendSMSCode() {
      if (!this.telephone) {
        this.$message.error("请输入手机号");
        return false;
      }
      if (this.telephone.length !== 11) {
        this.$message.error("请输入正确的手机号");
        return false;
      }
      this.message = "发送中...";
      this.loading = true;
      let result = {};
      const data = {
        loginName: this.telephone,
        smsType: this.smsType
      }
      uasSendSMSCode(data).then(res => {
        if (res.data.success) {
          this.loading = false;
          this.$message.success('短信已发送');
          this.setCountDown();
        } else {
          this.message = "获取验证码";
          this.loading = false;
          this.$message.error(res.data.message);
        }
      });
    },

    setCountDown() {
      this.countDown = 60;
      this.message = `剩余${this.countDown}s`;
      this.intervalId = window.setInterval(() => {
        --this.countDown;
        this.message =
          this.countDown === 0 ? "获取验证码" : `剩余${this.countDown}s`;
        if (this.countDown === 0) {
          clearInterval(this.intervalId);
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.send-sms-code {
  margin-right: 8px;
  font-weight: 400;
  color: #46b0e2;
  cursor: pointer;

  .el-button>span {
    color: #46b0e2;
  }
}
</style>
